import * as React from 'react';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import Stack from '@mui/material/Stack';
import { makeStyles } from '@mui/styles';
import moment from 'moment';


const useStyles=makeStyles({
  root:{
        overflow: "auto",
    height: "100%",
  }
  
})
const MyDatepicker = (props: any) => {
  const classes = useStyles()
    const { value, onChange, label, register,disabled, error , onOpen , onClose ,formatDate} = props;
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack spacing={3}>
            <DesktopDatePicker
              disabled={disabled}
              onClose={onClose || null}
              disablePast={props.disablePast? props.disablePast : false}
              label={label}
              value={value}
              inputFormat='dd MMMM, yy'
              
              onOpen={ onOpen || null }
             InputProps={{style:{
               borderRadius:"25px"
             }}}
              className={classes.root}
              {...register}
              PaperProps={classes.root}
              minDate={props.minDate ? props.minDate : null}
              onChange={onChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </Stack>
        </LocalizationProvider>
      );
    }

export default MyDatepicker;