import { Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material';
import React, { useImperativeHandle, useState, forwardRef, Suspense } from 'react';
//---------------------------------------------------
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import DropDownComponent from '../../components/Select/Select';
import Errormessage from '../../components/Errormessage/Errormessage';
import { showToast } from '../../components/Toast/Toast';
import useLocalStorage from '../../hooks/localStorage';
import useApiService from '../../services/api.service';
import Input from '../../components/Input/Input';
import Loader from '../../components/Loader/Loader';
import { useRef, useEffect } from 'react';
import Datatable from '../../components/Datatable/Datatable';
import EditIcon from "../../assets/images/editIcon.svg";
import DeleteIcon from "../../assets/images/deleteIcon.svg";
import AlertDialog from '../../components/Alertdialog/Dialog';
import Tooltip from '../../components/Tooltip/Tooltip';
import { makeStyles } from '@mui/styles';
import DepartmentEndpoints from './Deprtment.endpoints';
import Btn from '../../components/Button/Button';

const styles =
{
    container: { borderRadius: '20px', minHeight: '80vh', padding: "3vh", width: "auto", margin: "100px 40px 40px 260px",backgroundColor:"white" },
    dialogcontainer: { padding: '15px', width: '600px' },

}

const useStyles = makeStyles({
    columnEllipsis: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "block",
    },
});
const iconDivStyles =
{
    display: 'flex',
    cursor: "pointer",
}

const Departments = () => {
    const classes = useStyles()
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const ApiService = useApiService();
    const loginData = useLocalStorage.getItem("loginData");
    const [operation, setOperation] = useState('');
    const deleteTaskRef: any = useRef();
    const [clickedRow, setClickedRow]: any = useState({});
    const [departments, setDepartments]: any = useState([]);
    const [submission, setSubmission] = useState(false)

    const getDepartments = async () => {
        setLoading(true);

        const reqBody: any = {};

        const header =
        {
            access_token: loginData?.access_token
        }
        const reqData = {
            reqBody: reqBody,
            headers: header
        };

        await ApiService.get(DepartmentEndpoints.department(reqData))
            .then((res: any) => {
                setDepartments(res.data);
            }).finally(() => {
                setLoading(false);
            });
    }


    // ---------------validation schema-----------------
    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .trim()
            .required('This field is required'),
    });

    const {
        register,
        handleSubmit,
        control,
        formState: { errors, isSubmitting },
        getValues, setValue,
        reset
    } = useForm
            ({
                resolver: yupResolver(validationSchema)
            });

    useEffect(() => {
        getDepartments();
    }, [])


    const submit = async (data: any) => {
        // setLoading(true);
setSubmission(true)
        console.log("-----------FORM SUBMITTED----------")
        console.log(data);

        const reqBody: any =
        {
            name: data?.name,
        }
        if (operation === 'edit') {
            reqBody.department_id = clickedRow?.department_id
        }
        const header =
        {
            access_token: loginData?.access_token
        }
        const reqData =
        {
            reqBody: reqBody,
            headers: header
        };
        await ApiService[operation === 'edit' ? 'put' : 'post'](DepartmentEndpoints.department(reqData))
            .then((res: any) => {
                showToast('SUCCESS', res.message);
                getDepartments();
                resetFields();
                setOpen(false);
            })
            .catch((err:any)=>{
                showToast("ERROR",err.message)
            })
            .finally(()=>{
                setSubmission(false)
            })
    }

    const resetFields = () => {
        reset
            ({
                name: ''
            })
    }



    const TableIcons = (row: any) => {
        const handleEdit = (clickedRow: any) => {
            console.log("------------HANDLE EDIT IS CALLED----------")
            setValue('name', clickedRow?.name);
            setClickedRow(clickedRow);
            setOperation('edit');
            setOpen(true);
        }

        const handleDelete = (clickedRow: any) => {
            console.log("------------HANDLE DELETE IS CALLED----------")
            setClickedRow(clickedRow)
            deleteTaskRef.current.handleClickOpen();
        }



        return (
            <>
                <div style={iconDivStyles}>
                    <div>
                        <img src={EditIcon} onClick={() => {
                            handleEdit(row.row)
                        }}
                            className="icon_images"
                        />
                    </div>
                    <div>
                        <img src={DeleteIcon}
                            className="icon_images"
                            onClick={() => {
                                handleDelete(row.row)
                            }} />
                    </div>
                </div>
            </>
        )
    }

    const columns: any = [
        {
            name: "Department Name",
            selector: "name",
            wrap: true,
            minWidth: "125px",
        },
        {
name:"Lead",
selector:"lead",
wrap:true,
minWidth:"125px",
cell:(row:any)=>row["department_lead"]? row["department_lead"] : "-"
        },
        {
            name: "Actions",
            allowOverflow: true,
            button: true,
            selector: "is_active",
            cell: (row: any) => <div ><TableIcons row={row} /></div>
        },
    ];

    const onConfirm = async () => {
        console.log(clickedRow);
        const reqBody =
        {
            department_id: clickedRow?.department_id
        }

        const header = {
            access_token: loginData?.access_token
        }

        const reqData = {
            reqBody: reqBody,
            headers: header
        };

        await ApiService.delete(DepartmentEndpoints.department(reqData))
            .then((res: any) => {
                deleteTaskRef.current.handleClose();
                showToast('SUCCESS', res.message)
                getDepartments();
            })
    }

    const onDiscard = () => {
        deleteTaskRef.current.handleClose();
    };



    return <React.Fragment>
        <Loader loading={loading}>

            <Container maxWidth={false} style={styles.container} className='Users'>
                <Grid container spacing={2}>
                    <Grid item lg={12} md={12} className='display__style__1'>
                        <Button variant="contained" size="medium" 
                                                    style={{backgroundColor:"#007ebf",borderRadius:"20px"}}
                                                    onClick={() => {
                            setOperation('')
                            setOpen(true);
                            resetFields();
                        }}>Add New Department</Button>
                    </Grid>
                    <Grid item lg={12} md={12}>
                        {/* ------------------LISTING OF USERS------------ */}
                        <Datatable
                            columns={columns}
                            data={departments}
                            noDataComponent={!loading ? departments?.length ? " " :" No records to display " : " "}
                        />
                    </Grid>
                </Grid>
            </Container>


            <Dialog
                open={open}
                onClose={() => {
                    setOpen(false)
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div style={styles.dialogcontainer}>
                    <DialogTitle id="alert-dialog-title" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: "0px" }}>
                        <p className="dialog_title">
                            {operation === 'edit' ? 'Edit Department' : 'Add Department'}
                        </p>
                    </DialogTitle>
                    <form onSubmit={handleSubmit(submit)}>
                        <DialogContent >
                            <DialogContentText id="alert-dialog-description">
                                <Grid container spacing={1}>
                                    <Loader loading={submission}/>
                                    <Grid item lg={12} md={12} sm={12}>
                                        <Input
                                            // required={true}
                                            type="text"
                                            label="Department Name*"
                                            register={register('name')}
                                            // autoFocus={true}
                                            error={errors.name ? true : false}
                                            onChange={(e: any) => {
                                                setValue('name', e.target.value);
                                            }}

                                        />
                                        <Errormessage errormessage={errors.name ? errors.name.message : ''} />
                                    </Grid>
                                </Grid>
                            </DialogContentText>
                        </DialogContent>

                        <DialogActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                            <Btn
                                variant="outlined"
                                onClick={() => {
                                    resetFields();
                                    setOpen(false);
                                } } btnText={' Cancel'} type={'button'}/>
                               
                            <Btn type="submit" 
                            variant="contained" btnText={operation === 'edit' ? 'Update' : 'Save'}/>                                                        
                                
                        </DialogActions>
                    </form>
                </div>
            </Dialog>
            <Suspense fallback={""}>
                <AlertDialog
                    ref={deleteTaskRef}
                    description={"Are you sure you want to delete this Department?"}
                    title={"Delete Department"}
                    confirm={"Confirm"}
                    discard={"Discard"}
                    onConfirm={onConfirm}
                    onDiscard={onDiscard}
                ></AlertDialog>
            </Suspense>
        </Loader>
    </React.Fragment >
}
export default Departments;