import { Accordion, AccordionDetails, AccordionSummary, Container, FormControl, Grid, IconButton, InputLabel, Stack, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import Loader from '../../components/Loader/Loader';
import './Statementofwork.scss';
// -------------------ICONS IMAGES--------------
import EditIcon from "../../assets/images/editIcon.svg";
import useApiService from '../../services/api.service';
import useLocalStorage from '../../hooks/localStorage';
import { showToast } from '../../components/Toast/Toast';
//---------------------------------------------------
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Errormessage from '../../components/Errormessage/Errormessage';
import Input from "../../components/Input/Input";
import DropDownComponent from '../../components/Select/Select';
import Statementofworkendpoints from './Statementofwork.endpoints';
import MyDatepicker from '../../components/Datepicker/Datepicker';
import { styled } from '@mui/material/styles';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import Projectdialog from './Projectdialog/Projectdialog';
import Button from '../../components/Button/Button'
import CustomerDialog from './Customerdialog/Customer';
import Salespersondialog from './Salespersondialog/Salesperson';
import Departmentdialog from './Departmentdialog/Departmentdialog';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircle';
import MuiDelete from '@mui/icons-material/Delete';
import getCurrencySymbol from './getCurrencySymbol';
import Tooltip from '../../components/Tooltip/Tooltip';
import { makeStyles } from '@mui/styles';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import NumberFormat from 'react-number-format';
import { useLocation } from 'react-router-dom';
import { Navigate, useNavigate } from 'react-router-dom';
import Btn from '../../components/Button/Button';
import { ExpandMoreOutlined } from '@mui/icons-material';
import moment from 'moment';


const styles =
{
    container: { borderRadius: '20px', minHeight: '80vh', padding: "3vh", width: "auto", margin: "100px 40px 40px 260px", backgroundColor: "white" },
    dialogcontainer: { padding: '15px' }
}



const useStyles = makeStyles({
    columnEllipsis: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "block",
    },
});


const UploadInput =
    styled('input')({
        display: 'none',
    });

const iconDivStyles =
{
    display: 'flex',
    cursor: "pointer",
}

const dummySOWStatus =
    [
        { name: 'Open', status: 1 },
        { name: "Inprogess", status: 2 },
        { name: "Onhold", status: 3 },
        { name: "Completed", status: 4 }
    ]

const dummyPaymentTermsInDays =
    [
        { id: 1, name: '7 days', value: 7 },
        { id: 2, name: "15 days", value: 15 },
        { id: 3, name: "30 days", value: 30 },
        { id: 4, name: "45 days", value: 45 },
        { id: 5, name: "60 days", value: 60 },
        { id: 6, name: "90 days", value: 90 },
    ]

const dummyMilestoneStatus =
    [
        { id: 1, type: 'Kick Off' },
        { id: 2, type: 'Design' },
        { id: 3, type: 'Development' },
        { id: 4, type: 'Quality Assurance' },
        { id: 5, type: 'UAT' },
        { id: 6, type: 'Go Live' },
        { id: 7, type: 'Part Completion' },
        { id: 8, type: 'Completion' }
    ]
const AddStatementOfWork = (props: any) => {
    console.log(props, "--------------------props----------------");

    const classes = useStyles()
    const [deleted_milestone, setDeleted_milestone]: any = useState([])
    const [deleted_salesperson, setDeleted_salesperson]: any = useState([])
    const [deleted_department, setDeleted_department]: any = useState([])
    const [loading, setLoading] = useState(true);
    const [sowData, setSowdata] = useState([]);
    const [tempSOWdata, setTempSOWdata] = useState([]);

    const loginData = useLocalStorage.getItem("loginData")
    const ApiService = useApiService();
    const [clickedRow, setClickedRow]: any = useState();
    const [operation, setOperation] = useState('');
    const [open, setOpen] = useState(false);
    const { state } = useLocation() as any;
    //--------------------------------------------------
    // ----------------------LISTING DATA-------------------
    const [allProjects, setAllProjects]: any = useState([]);
    const [projects, setProjects]: any = useState([]);
    const [customers, setCustomers]: any = useState([]);
    const [departments, setDepartments]: any = useState([]);
    const [PM, setPMs] = useState([]);

    const [TL, setTLs] = useState([]);
    const [saleperson, setSaleperson]: any = useState([]);
    const [fileUrl, setFileUrl] = useState('');

    //////////////REFS/////////////////
    const projectTaskRef: any = useRef();
    const customerTaskRef: any = useRef();
    const salespersonTaskRef: any = useRef();
    const departmentTaskRef: any = useRef();
    const navigate = useNavigate()

    // -----------------TESTING-------------

    let [tempPrefillValues, setTempPrefillValues]: any = useState
        ({
            project_detail: '',
            customer_detail: '',
            status: '',
            payment_terms: '',
            pm_id: '',
            lead_id: '',
            currency: '',
            upload_file_type: 1
        })
    const showTooltip = (data: any) => {
        if (data && data.length > 20) {
            return (
                <Tooltip
                    content={data}
                    direction={"bottom"}
                    style={{
                        backgroundColor: "rgba(97, 97, 97, 0.92)",
                        marginLeft: "75px"
                    }}
                    wrapperStyle={{
                        width: "200px",
                    }}
                >
                    <span className={classes.columnEllipsis}>{data}</span>
                </Tooltip>
            );
        } else {
            return <span>{data}</span>;
        }
    };
    const getParticulatSOW = async (sow_id: number) => {
        const reqBody = {
            sow_id: sow_id
        };
        const header =
        {
            access_token: loginData?.access_token
        }
        const reqData = {
            reqBody: reqBody,
            headers: header
        };
        await getSOWPageDropdowndata();
        await ApiService.get(Statementofworkendpoints.sow(reqData))
            .then((res: any) => {
                console.log("-----------PARTICULAR SOW DATA---------");
                console.log(res.data);
                setClickedRow(res.data);
                setPrefillData(res.data);
            }).catch((err: any) => {
                console.log(err);
                showToast('ERROR', err.message);
            }).finally(() => {
                setTimeout(() => {
                    setLoading(false);
                }, 2000);

            })

    }
    const getSOWPageDropdowndata = async () => {
        if (state.operation == "edit" || state.operation == "view") {
            setLoading(true);
        }

        let tempDepartments: any = [];
        let tempPMs: any = []
        let tempLEADs: any = [];
        let tempSalePerson: any = [];

        const reqBody = {}
        const header = {
            access_token: loginData?.access_token
        }
        const reqData = {
            reqBody: reqBody,
            headers: header
        };

        await ApiService.get(Statementofworkendpoints.sow_department(reqData))
            .then((res: any) => {
                tempDepartments = res.data;
            }).catch((err: any) => {
                showToast('ERROR', err.message);
            })

        await ApiService.get(Statementofworkendpoints.sow_salesperson(reqData))
            .then((res: any) => {
                tempSalePerson = res.data;
            }).catch((err: any) => {
                showToast('ERROR', err.message);
            })
        await ApiService.get(Statementofworkendpoints.users(reqData))
            .then((res: any) => {
                let data = res.data;
                tempPMs = res.data;
                tempLEADs = res.data;
            }).catch((err: any) => {
                showToast('ERROR', err.message);
            })


        setDepartments(tempDepartments);
        setPMs(tempPMs);
        setTLs(tempLEADs);
        setSaleperson(tempSalePerson);
        if (state.operation == "edit" || state.operation == "view") {
            setTimeout(() => {
                setLoading(false);
            }, 500);
        }
    }
    const getDropdownsData = async () => {

        let tempProjects: any = [];
        let tempCustomers: any = [];

        const reqBody = {}
        const header =
        {
            access_token: loginData?.access_token
        }
        const reqData =
        {
            reqBody: reqBody,
            headers: header
        };

        await ApiService.get(Statementofworkendpoints.sow_project(reqData))
            .then((res: any) => {
                tempProjects = res.data;
            }).catch((err: any) => {
                showToast('ERROR', err.message);
            })

        await ApiService.get(Statementofworkendpoints.sow_customer(reqData))
            .then((res: any) => {
                tempCustomers = res.data;
            }).catch((err: any) => {
                showToast('ERROR', err.message);
            })
        console.log("projects----------DROPDOWN DATA----------")

        console.log(tempProjects, "----------temp projects------------------");
        setValue('all_projects', tempProjects)
        setAllProjects(tempProjects);
        setProjects(tempProjects)
        setCustomers(tempCustomers);
    }

 ////////////////get all SOW'S//////////////
    const getSOWs = async () => {
        setLoading(true);
        const reqBody = {}
        const header = {
            access_token: loginData?.access_token
        }
        const reqData = {
            reqBody: reqBody,
            headers: header
        };

        await ApiService.get(Statementofworkendpoints.sow(reqData))
            .then((res: any) => {
                setTempSOWdata(res.data);
                setSowdata(res.data);
                // statusTypeListing(res.data)
            }).catch((err: any) => {
                showToast('ERROR', err.message);
                /////////set loading to false/////////
            }).finally(() => {
                setLoading(false)
            });
    }

    // --------------- schema-----------------
    const validationSchema = Yup.object().shape({
        title:
            Yup.string()
                .trim()
                .required('This field is required'),
        pm_id: Yup.string()
            .trim()
            .required('Please select a Project Manager'),
        lead_id: Yup.string()
            .trim()
            .required('Please select a Department Lead'),
        status: Yup.string()
            .trim()
            .required('SOW status is required'),
        project_detail: Yup
            .string()
            .required('This field is required'),
        payment_terms: Yup
            .string()
            .required('This field is required'),
        customer_detail: Yup
            .string()
            .required('This field is required'),
        amount: Yup.number()
            // .typeError('Amount must be a number')
            .required("This field is required")
            .min(0, "Please enter a valid amount"),
        salesperson: Yup.array().of(
            Yup.object().shape({
                sale_person_id: Yup.string().required("This field is required"),
                share: Yup.number()
                    .typeError('Share must be a number')
                    .required("This field is required")
                    .min(1, "Please enter a valid share %")
                    .max(100, "Please enter a valid share %"),

            })
        ),
        end_date: Yup.date()
            .when("start_date",
                (start_date, Yup) => start_date && Yup.min(start_date, "Enter a valid end date"))
        ,
        department: Yup.array().of(
            Yup.object().shape({
                department_id: Yup.string().required("This field is required"),
                share: Yup.number()
                    .typeError('Share must be a number')
                    .required("This field is required")
                    .min(1, "Please enter a valid share %")
                    .max(100, "Please enter a valid share %")
            })
        ),
        milestone: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().required("This field is required"),
                amount: Yup.number()
                    .typeError('Amount must be a number')
                    .required("This field is required")
                    .min(0, "Please enter a valid amount"),
                type: Yup.string().required("This field is required"),
                due_date: Yup.date()
                    .when("invoice_date",
                        (invoice_date, Yup) => invoice_date && Yup.min(invoice_date, "Enter a valid due date"))
            })
        )
    })

    const ResetFields = () => {
        reset
            ({
                title: '',
                description: '',
                // .toISOString().split('T')[0]
                start_date: new Date().toISOString(),
                // .toISOString().split('T')[0]
                end_date: new Date().toISOString(),
                pm_id: undefined,
                lead_id: undefined,
                amount: '',
                comments: '',
                status: 1,
                project_detail: undefined,
                customer_detail: undefined,
                payment_terms: undefined,
                milestone: [],
                department: [],
                salesperson: [],
                upload_link: ''
            })

        setFileUrl('');


        setTempPrefillValues({
            project_detail: '',
            customer_detail: '',
            status: 1,
            payment_terms: '',
            pm_id: '',
            lead_id: '',
        })

        setValue('search_item', '');
    }

    const setPrefillData = (prefillData: any) => {
        if (prefillData) {
            console.log("-------------------SHOW PREFILLDATA---------------");
            const data = prefillData;
            console.log(prefillData);
            setValue('comments', data?.comments);
            setValue('title', data?.title);
            setValue('amount', data?.amount?.toString());
            setValue('pm_id', data?.pm_id);
            setValue('lead_id', data?.lead_id);
            setValue('status', parseInt(data?.status));
            setValue('start_date', data?.start_date);
            setValue("end_date", data?.end_date);
            setValue('payment_terms', data?.payment_terms);
            setValue('project_detail', data?.project_id);
            setValue('customer_detail', data?.customer_id);
            setValue('upload_link', data?.sow_document);

            setTempPrefillValues
                ({
                    project_detail: data?.project_id,
                    customer_detail: data?.customer_id,
                    status: parseInt(data?.status),
                    payment_terms: data?.payment_terms,
                    lead_id: data?.lead_id,
                    pm_id: data?.pm_id,
                    currency: getCurrencySymbol(data?.customer_billing_currency),
                    upload_file_type: data?.upload_file_type
                })

            data?.department.map((value: any) => {
                departmentAppend
                    ({
                        department_id: value.department_id,
                        share: value.share,
                        amount: value.amount,
                        sow_department_id: value.sow_department_id
                    })
            })

            data?.milestone.map((value: any) => {
                milestoneAppend
                    ({
                        name: value.name,
                        amount: value.amount,
                        invoice_date: value.invoice_date.split('T')[0],
                        due_date: value.due_date.split('T')[0],
                        comments: value.comments,
                        milestone_id: value.milestone_id,
                        type: value.type
                    })
            })

            data?.sale_person?.map((value: any) => {
                salespersonAppend
                    ({
                        sale_person_id: value.sale_person_id,
                        share: value.share,
                        sales_project_id: value.sales_project_id,
                        amount: value.amount
                    })
            })
            // -------------FILTERED PROJECTS ON THE BASIS OF CUSTOMER----------
            let tempPrefillProjects: any = [];
            console.log(allProjects, "allProjects");
            console.log(getValues("all_projects"), "--------setvalues")
            tempPrefillProjects = getValues("all_projects")?.filter((value: any) => {
                return data?.customer_id === value.customer_id
            })
            console.log(tempPrefillProjects, "Prefill");

            setProjects(tempPrefillProjects);
        }
    window.scrollTo(0,0)
console.log(getCurrencySymbol('INR'),"==============eud")
    }

     const {
        register,
        handleSubmit,
        control,
        formState: { errors, },
        getValues, setValue,
        reset
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const { fields: milestoneFields, append: milestoneAppend, remove: milestoneRemove } = useFieldArray({ name: "milestone", control });
    const { fields: departmentFields, append: departmentAppend, remove: departmentRemove } = useFieldArray({ name: "department", control });
    const { fields: salespersonFields, append: salespersonAppend, remove: salespersonRemove } = useFieldArray({ name: "salesperson", control });



    // ------------------------TESTING--------------------------
    const ImageHandler = async (e: any) => {

        let file = e.target.files[0];
        let reader = new FileReader();

        let fiename = e?.target?.files[0]?.name;
        let ext = fiename.substring(fiename.lastIndexOf('.') + 1);


        let extArr = ['pdf', 'xls', 'xlsx', 'doc', 'docx'];

        let bool = false;

        extArr?.map((value: any) => {
            if (value === ext)
                bool = true;
        })


        if (!bool) {
            showToast('ERROR', 'File extension must be in between pdf,xls,xlsx,doc,docm,docx,docx')
            return;
        }
        setLoading(true);
        // ------------------------------------------------url work -------------------------------------------
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        const res = await ApiService.postFormData(
            Statementofworkendpoints.uploadFile(formData)
        );
        setFileUrl(res.data.url);
        setTimeout(() => {
            setLoading(false);
        }, 1000)
e.target.value = '';
    };

    useEffect(() => {
        if (state.operation == "add") {
            setOperation('create')
            ////////reset the fields//////
            ResetFields();
            // salespersonAppend({});
            // milestoneAppend
            //     ({
            //         type: 8
            //     });
            // departmentAppend({});
            setValue("amount", '')
            getDropdownsData();
            getSOWPageDropdowndata();
            setOpen(true)

            setLoading(false);
        }

        if (state.operation == "edit" && state.sowId) {
            console.log("Condition ")
            ResetFields();
            getDropdownsData();
            getParticulatSOW(state.sowId);
            setOperation('edit');
            setOpen(true);        
        }
        if (state.operation == "view" && state.sowId) {
            setLoading(true);
            ResetFields();
            getDropdownsData();
            getParticulatSOW(state.sowId);
            setOperation('view');
            setOpen(true);
        }
    }, [])

    const onSubmit = async (data: any) => {

        const Linkregex = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;

        console.log(data);
        ////////////////////validations for file upload///////////////
        if (!data?.upload_link?.trim() && !fileUrl) {
            showToast('ERROR', 'Please upload either link or file')
            return;
        }
        if (data?.upload_link?.trim() && fileUrl) {
            showToast('ERROR', 'Please upload either link or file')
            return;
        }
        if (data?.upload_link?.trim() && !Linkregex.test(data?.upload_link?.trim())) {
            showToast('ERROR', 'Please upload a valid link')
            return;
        }

        if (!data?.milestone?.length) {
            showToast('ERROR', 'Milestone is must')
            return;
        }

        if (!data?.department?.length) {
            showToast('ERROR', 'Department is must')
            return;
        }

        let Dshare = 0;
        data?.department?.map((value: any) => {
            Dshare += parseInt(value.share)
        })

        if (Dshare !== 100) {
            showToast('ERROR', 'Department share must be 100%')
            return;
        }

        let SalesShare = 0;
        data?.salesperson?.map((value: any) => {
            SalesShare += parseInt(value.share)
        })

        if (SalesShare > 100) {
            showToast('ERROR', 'Salesperson share must be less than 100%')
            return;
        }

        let salespersonArr: any = []

        salespersonArr = data?.salesperson?.map((value: any, index: number) => {
            return value.sale_person_id
        })

        let departmentArr = data?.department?.map((value: any, index: number) => {
            return value.department_id;
        })

        if (salespersonArr.length !== [...Array.from(new Set(salespersonArr))].length) {
            showToast('ERROR', 'Salesperson must be unique')
            return;
        }
        if (departmentArr.length !== [...Array.from(new Set(departmentArr))].length) {
            showToast('ERROR', 'Department must be unique')
            return;
        }

        console.log(data);

        console.log("----------FORM SUBMITTED----------");
        console.log(data)
        console.log(getValues('start_date'))
        console.log(getValues('end_date'));
        let milestoneAmount: number = 0
        const reqBody: any =
        {
            title: data.title,
            start_date: getValues('start_date')?.split('T')[0],
            end_date: getValues('end_date')?.split('T')[0],
            amount: parseInt(data.amount),
            pm_id: parseInt(data.pm_id),
            lead_id: parseInt(data.lead_id),
            comments: data.comments,
            project_id: parseInt(data.project_detail),
            customer_id: parseInt(data.customer_detail),
            milestone: data.milestone.map((value: any, index: number) => {
                milestoneAmount += parseInt(value.amount)
                return { ...value, amount: parseInt(value.amount), due_date: value.due_date.toISOString().split('T')[0], invoice_date: new Date(value.invoice_date).toISOString().split('T')[0] }
            }),
            department: data.department.map((value: any) => {
                return { ...value, share: parseInt(value.share) }
            }),
            sale_person: data.salesperson?.length ? data.salesperson?.map((value: any) => {
                return { ...value, share: parseInt(value.share) }
            }) : null,
            sow_document: fileUrl ? fileUrl : data?.upload_link?.trim(),
            status: parseInt(data.status),
            payment_terms: parseInt(data.payment_terms)
        }
        if (milestoneAmount > parseInt(data.amount)) {
            showToast("ERROR", "Milestone(s) amount cannot be greater than total amount.")
            return;
        }
        console.log(reqBody);
        console.log(milestoneAmount, "------------------milestone amount-----------------")

        const header =
        {
            access_token: loginData?.access_token
        }
        const reqData = {
            reqBody: reqBody,
            headers: header
        };

        if (operation === 'edit') {
            reqBody.sow_id = clickedRow?.sow_id;
            reqBody.deleted_milestone = deleted_milestone
            reqBody.deleted_sales_person = deleted_salesperson
            reqBody.deleted_department = deleted_department
            delete reqBody['sale_person']
            delete reqBody['milestone']
            delete reqBody['department']
            reqBody.milestone = data.milestone.map((value: any, index: number) => {
                return { ...value, milestone_id: value.milestone_id ? value.milestone_id : null, amount: parseInt(value.amount), due_date: value.due_date.toISOString().split('T')[0], invoice_date: new Date(value.invoice_date).toISOString().split('T')[0] }
            })


            reqBody.department = data.department.map((value: any) => {
                return { ...value, sow_department_id: value.sow_department_id ? value.sow_department_id : null, share: parseInt(value.share) }
            })

            reqBody.sale_person = data.salesperson?.length ? data.salesperson?.map((value: any) => {
                return { ...value, sales_project_id: value.sales_project_id ? value.sales_project_id : null, share: parseInt(value.share) }
            }) : []
        }
        
        setLoading(true);
        window.scrollTo(0,0)
        await ApiService[operation === 'create' ? "post" : "put"](Statementofworkendpoints.sow(reqData))
            .then((res: any) => {
                showToast('SUCCESS', res.message);
                getSOWs();
                // getDropdownsData();
                setOpen(false);
                navigate('/statement-of-work')
            }).
            catch((err: any) => {
                showToast('ERROR', err.message);
                /////////set loading to false/////////
            }).finally(() => {
                setLoading(false);
                setDeleted_milestone([])
                setDeleted_salesperson([])
                setDeleted_department([])
            })
    }

    const calculatePercentile = (data?: any) => {

        let totalAmount = parseInt(getValues('amount'))
        let totalSalespersonAmount = 0;
        getValues('salesperson')?.map((value: any, index: any) => {
            totalSalespersonAmount += value.amount ? parseInt(value.amount) : 0
            setValue(`salesperson.${index}.share`, Math.round((getValues(`salesperson.${index}.amount`) * 100) / totalAmount))
            // console.log(getValues(`salesperson.${index}.share`), "percentile change------")
        })
        // calculateShare()
        let remainingAmount = totalAmount - totalSalespersonAmount

        getValues('department')?.map((value: any, index: number) => {
            if (data) {
                setValue(`department.${index}.share`, Math.round((getValues(`department.${index}.amount`) * 100) / remainingAmount))
            } else {
                let share = getValues(`department.${index}.share`) ? getValues(`department.${index}.share`) : 0;
                setValue(`department.${index}.amount`, Math.round((remainingAmount / 100) * share));
            }

        })
        /////////////update amoutn if milestone amount is 1/////////
        if (getValues('milestone')?.length === 1) {
            setValue(`milestone.0.amount`, totalAmount)
        }
    }

    const calculateShare = () => {

        let totalAmount = parseInt(getValues('amount'));
        let salespersonshare = 0;
        console.log(getValues('salesperson'))
        getValues('salesperson')?.map((value: any, index: number) => {
            salespersonshare += value.share ? parseInt(value.share) : 0;
            setValue(`salesperson.${index}.amount`, Math.round((totalAmount / 100) * parseInt(value.share)))
        })

        let remainingAmount = totalAmount - (totalAmount / 100) * salespersonshare;
        let totalShare = 0
        getValues('department')?.map((value: any, index: number) => {
            totalShare += parseInt(getValues(`department.${index}.share`))
            let share = getValues(`department.${index}.share`) ? getValues(`department.${index}.share`) : 0;
            setValue(`department.${index}.amount`, Math.round((remainingAmount / 100) * share));
        })
        let remainingShare = 100 - totalShare
        setValue("remaining_share", remainingShare)
        /////////////update amoutn if milestone amount is 1/////////
        if (getValues('milestone')?.length === 1) {
            setValue(`milestone.0.amount`, totalAmount)
        }
    }


    const updateDate = (i: number) => {
        milestoneFields.map((val: any, index: number) => {

            let invoice_date = new Date(getValues(`milestone.${index}.invoice_date`))

            let d = getValues(`milestone.${index}.invoice_date`) ? new Date(invoice_date) : new Date();
            let dayCount = 0;

            dummyPaymentTermsInDays.map((value: any) => {
                if (value.id === getValues('payment_terms')) {
                    dayCount = value.value;
                }
            })
            d.setDate(d.getDate() + dayCount);

            setValue(`milestone.${index}.invoice_date`, getValues(`milestone.${index}.invoice_date`) ? new Date(invoice_date) : new Date().toISOString().split('T')[0])
            setValue(`milestone.${index}.due_date`, d.toISOString().split('T')[0])

            // console.log(d,new Date(invoice_date),"======index======")
            console.log(getValues(`milestone.${index}.invoice_date`), "======invoice date--------")
            console.log(getValues(`milestone.${index}.due_date`), "========due date=====")
        })
    }

    const handleBack = () => {
        navigate('/statement-of-work')
    }

    // const enableScrolling = () =>{
    //     document.body.style.overflow = 'scroll'
    // }
    // const disableScrolling =() =>{
    //     document.body.style.overflow = 'hidden'
    // }
   
    return <React.Fragment>
        <Loader loading={loading}>
            <div style={{ maxHeight: "100vh" }}>

                <div style={{ cursor: operation === 'view' ? 'not-allowed' : 'pointer' }}>
                    <div style={{ pointerEvents: operation === 'view' ? 'none' : 'visible' }}>
                        <Container maxWidth={false} style={{ ...styles.container, display: open ? 'block' : 'none' }}>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div style={styles.dialogcontainer}>

                                    <div id="alert-dialog-title" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: "20px 0px" }}>
                                        <h2 className="dialog_title">
                                            {operation == 'edit' ? 'Edit SOW' : operation == 'view' ? "View SOW" : "Add SOW"}
                                        </h2>
                                    </div>

                                    <Grid container spacing={2} style={{ marginTop: "30px" }}>
                                        {operation === 'view' && <Grid item xs={12} lg={12} md={12} style={{ display: 'flex', justifyContent: 'end' }}>
                                            <img src={EditIcon} style={{ pointerEvents: 'visible', cursor: 'pointer' }} onClick={(e: any) => setOperation('edit')} />
                                        </Grid>}

                                        <Grid item xs={6} md={6} lg={6}>
                                            <h3 style={{ marginBottom: "10px" }}>SOW Customer</h3>
                                            <Controller
                                                control={control}
                                                name={`customer_detail`}
                                                render={({ field: any }) => (
                                                    <DropDownComponent
                                                        value={tempPrefillValues.customer_detail}
                                                        disabled={operation === 'view'}
                                                        label='Select Customer*'
                                                        menuItems={customers}
                                                        valueKey='customer_id'
                                                        autoFocus={true}
                                                        showNameKey='name'
                                                        showCreateOption={true}
                                                        popupFunction={(e: any) => {
                                                            customerTaskRef.current.handleClickCustomerDialogOpen();
                                                        }}
                                                        error={errors.customer_detail ? true : false}
                                                        onChange={(e: any) => {

                                                            setLoading(true);

                                                            setValue('customer_detail', e.target.value);

                                                            let tempCurrency: any = '';

                                                            customers?.map((value: any, index: number) => {
                                                                console.log(value);
                                                                if (value.customer_id === e.target.value)
                                                                    tempCurrency = value.billing_currency;
                                                            })

                                                            setTempPrefillValues({ ...tempPrefillValues, customer_detail: e.target.value, project_detail: '', currency: getCurrencySymbol(tempCurrency) })
                                                            let custId = parseInt(e.target.value);

                                                            let tempProjects = [];
                                                            tempProjects = allProjects?.filter((value: any) => custId === value.customer_id)
                                                            setProjects(tempProjects);

                                                            setTimeout(() => {
                                                                setLoading(false)
                                                            }, 500)
                                                        }}
                                                    />
                                                )}
                                            />

                                            <Errormessage errormessage={errors.customer_detail ? errors.customer_detail.message : ''} />
                                        </Grid>

                                        <Grid item xs={6} md={6} lg={6}>
                                            <h3 style={{ marginBottom: "10px" }}>SOW Project</h3>
                                            <Controller
                                                control={control}
                                                name={`project_detail`}
                                                render={({ field }) => (
                                                    <DropDownComponent
                                                        value={tempPrefillValues.project_detail}
                                                        disabled={operation === 'view' || !tempPrefillValues.customer_detail}
                                                        label='Select Project*'
                                                        menuItems={projects}
                                                        valueKey='project_id'
                                                        showNameKey='project_name'
                                                        showCreateOption={true}
                                                        popupFunction={(e: any) => {
                                                            projectTaskRef.current.handleClickProjectDialogOpen();
                                                        }}
                                                        error={errors.project_detail ? true : false}
                                                        onChange={(e: any) => {
                                                            setValue('project_detail', e.target.value);
                                                            setTempPrefillValues({ ...tempPrefillValues, project_detail: e.target.value })
                                                        }}
                                                    />
                                                )}
                                            />

                                            <Errormessage errormessage={errors.project_detail ? errors.project_detail.message : ''} />
                                        </Grid>
                                    </Grid>




                                    {/* =========================sow-details======================= */}
                                    <Accordion
                                        defaultExpanded={state.operation == "add" ? false : true}
                                        style={{ margin: "20px 0px" }}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreOutlined />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            style={{ display: "flex", justifyContent: "flex-end" }}
                                        >
                                            <h3>SOW Details</h3>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={2}>
                                               <Grid item xs={6}>
                                                    <Input
                                                        disabled={operation === 'view'}
                                                        // required={true}
                                                        style={{ marginTop: '-2px' }}
                                                        type="text"
                                                        label="SOW Title*"

                                                        register={register('title')}
                                                        autoFocus={false}
                                                        error={errors.title ? true : false}
                                                    />
                                                    <Errormessage errormessage={errors.title ? errors.title.message : ''} />
                                                </Grid>
                                                <Grid item xs={6} md={6} lg={6}>
                                                    <Input
                                                        style={{ marginTop: '-5px' }}
                                                        disabled={operation === 'view'}
                                                        required={false}
                                                        type="text"
                                                        label="Comments"
                                                        register={register('comments')}
                                                        autoFocus={false}
                                                        maxRows={10}
                                                    />
                                                </Grid>

                                                <Grid item xs={6} md={6} lg={6} >
                                                    <Controller
                                                        control={control}
                                                        name={`status`}
                                                        render={({ field: any }) => (
                                                            <DropDownComponent
                                                                value={tempPrefillValues.status}
                                                                disabled={operation === 'view'}
                                                                label='SOW Status*'
                                                                menuItems={dummySOWStatus}
                                                                valueKey='status'
                                                                showNameKey='name'
                                                                error={errors.status ? true : false}
                                                                onChange={(e: any) => {
                                                                    setValue('status', e.target.value);
                                                                    setTempPrefillValues({ ...tempPrefillValues, status: e.target.value })
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    <Errormessage errormessage={errors.status ? errors.status.message : ''} />
                                                </Grid>

                                                <Grid item xs={6} md={6} lg={6} >
                                                    <Controller
                                                        control={control}
                                                        name={`payment_terms`}
                                                        render={({ field: any }) => (
                                                            <DropDownComponent
                                                                value={tempPrefillValues.payment_terms}
                                                                disabled={operation === 'view'}
                                                                label='Payment Terms*'
                                                                menuItems={dummyPaymentTermsInDays}
                                                                valueKey='id'
                                                                showNameKey='name'
                                                                error={errors.payment_terms ? true : false}
                                                                onChange={(e: any) => {
                                                                    setValue('payment_terms', e.target.value);
                                                                    updateDate(e.target.value)
                                                                    setTempPrefillValues({ ...tempPrefillValues, payment_terms: e.target.value })
                                                                }}
                                                            />
                                                        )}
                                                    />

                                                    <Errormessage errormessage={errors.payment_terms ? errors.payment_terms.message : ''} />
                                                </Grid>

                                                <Grid item xs={6} md={6} lg={6}>
                                                    <Controller
                                                        control={control}
                                                        name="start_date"
                                                        render={({ field: any }) => (
                                                            <MyDatepicker
                                                                disabled={operation === 'view'}
                                                                label='Start Date*'
                                                                value={getValues('start_date') ? getValues('start_date') : setValue('start_date', new Date().toISOString())}
                                                                onChange={(newValue: any) => {
                                                                    setValue('start_date', newValue.toISOString());
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </Grid>


                                                <Grid item xs={6} md={6} lg={6}>
                                                    <Controller
                                                        control={control}
                                                        name="end_date"
                                                        render={({ field: any }) => (
                                                            <MyDatepicker
                                                                disabled={operation === 'view'}
                                                                label='End Date*'
                                                                
                                                                value={getValues('end_date') ? getValues('end_date') : setValue('end_date', new Date().toISOString())}
                                                                onChange={(newValue: any) => {
                                                                    setValue('end_date', newValue.toISOString());
                                                                }}
                                                                error={errors.end_date ? true : false}
                                                            />
                                                        )}
                                                    />
                                                    <Errormessage errormessage={errors.end_date ? errors.end_date.message : ''} />
                                                </Grid>
                                                <Grid item xs={6} md={6} lg={6}>
                                                    <Controller
                                                        control={control}
                                                        name={`pm_id`}
                                                        render={({ field: any }) => (
                                                            <DropDownComponent
                                                                value={tempPrefillValues.pm_id}
                                                                disabled={operation === 'view'}
                                                                label='Project Manager*'
                                                                menuItems={PM}
                                                                valueKey='user_id'
                                                                showNameKey='name'
                                                                error={errors.pm_id ? true : false}
                                                                onChange={(e: any) => {
                                                                    setValue('pm_id', e.target.value);
                                                                    setTempPrefillValues({ ...tempPrefillValues, pm_id: e.target.value })
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    <Errormessage errormessage={errors.pm_id ? errors.pm_id.message : ''} />
                                                </Grid>
                                                <Grid item xs={6} md={6} lg={6}>
                                                    <Controller
                                                        control={control}
                                                        name={`lead_id`}
                                                        render={({ field: any }) => (
                                                            <DropDownComponent
                                                                value={tempPrefillValues.lead_id}
                                                                disabled={operation === 'view'}
                                                                label='Department Lead*'
                                                                menuItems={TL}
                                                                valueKey='user_id'
                                                                showNameKey='name'
                                                                error={errors.lead_id ? true : false}
                                                                onChange={(e: any) => {
                                                                    setValue('lead_id', e.target.value);
                                                                    setTempPrefillValues({ ...tempPrefillValues, lead_id: e.target.value })
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    <Errormessage errormessage={errors.lead_id ? errors.lead_id.message : ''} />
                                                </Grid>

                                            </Grid >

                                            <Grid container spacing={2} style={{ marginTop: "35px" }}>
                                                <Grid item xs={12} lg={12} md={12} style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                                    <h3>SOW Document</h3>
                                                </Grid>

                                                <Grid item xs={4} md={4} lg={4}>
                                                    <Input
                                                        style={{ marginTop: '-2px' }}
                                                        disabled={operation === 'view'}
                                                        required={false}
                                                        type="text"
                                                        label="Upload Link"
                                                        register={register('upload_link')}
                                                        autoFocus={false}
                                                    />
                                                </Grid>

                                                <Grid item xs={2} md={2} lg={2} style={{ display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                                                    <p>
                                                        Or
                                                    </p>
                                                </Grid>

                                                <Grid item xs={4} md={4} lg={4} style={{ marginTop: '-15px' }}>
                                                    <Stack direction="row" alignItems="center" spacing={2}>
                                                        <label htmlFor="contained-button-file">
                                                            <UploadInput accept="file/*" id="contained-button-file" multiple type="file"
                                                                onChange={(e: any) => ImageHandler(e)} />
                                                            <IconButton disabled={operation === 'view'} color="primary" aria-label="upload picture" component="span">
                                                                <CloudUploadOutlinedIcon fontSize='large' />

                                                            </IconButton>

                                                        </label>
                                                        <label>{showTooltip(fileUrl)}</label>
                                                    </Stack>
                                                    <div style={{ pointerEvents: 'visible' }}>Uploaded file link : {fileUrl ? <a style={{ marginBottom: "-10px" }} href={fileUrl} target="_blank">Download now</a> : 'Choose a file please'}</div>
                                                </Grid>
                                                <Grid item lg={2} md={2} sm={2}>
                                                    <Button
                                                        type='button'
                                                        disable={operation == 'view'}
                                                        variant='contained'
                                                        btnText='Reset'
                                                        onClick={(e: any) => { setValue('upload_link', ''); setFileUrl('') }}
                                                    ></Button>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                    {/* ===============financials==============  */}
                                    <Accordion
                                        defaultExpanded={state.operation == "add" ? false : true}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreOutlined />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                            style={{ display: "flex", justifyContent: "flex-end" }}
                                        >
                                            <h3>Financials</h3>
                                        </AccordionSummary>
                                        <AccordionDetails>

                                            <Grid container spacing={2}>


                                                <Grid item xs={6} md={6} lg={6}>
                                                    <Controller
                                                        control={control}
                                                        name={'amount'}

                                                        render={({ field: any }) => (
                                                            <FormControl focused className="col" variant='outlined' style={{ width: "100%" }}>
                                                                <InputLabel className='mText' style={{ backgroundColor: "white", color: "grey",padding:"0px 20px 0px 10px" }}>Amount*</InputLabel>
                                                                <NumberFormat
                                                                    autoFocus={false}
                                                                    disabled={operation === 'view'}
                                                                    // required={true}
                                                                    thousandsGroupStyle="lakh"
                                                                    displayType='input'
                                                                    value={getValues("amount")}
                                                                    prefix={tempPrefillValues.currency ? `${tempPrefillValues.currency} ` : ""}
                                                                    allowNegative={false}
                                                                    customInput={TextField}
                                                                    thousandSeparator={true}
                                                                    autoComplete='off'
                                                                    InputProps={{

                                                                        style: {
                                                                            borderRadius: "25px",

                                                                            // marginTop:"10px",
                                                                        }

                                                                    }}
                                                                    onValueChange={(values: any) => {
                                                                        const { formattedValue, value } = values;
                                                                        setValue('amount', value)
                                                                        calculateShare();

                                                                    }}
                                                                    error={errors.amount ? true : false}
                                                                />
                                                            </FormControl>
                                                        )}
                                                    />
                                                    <Errormessage errormessage={errors.amount ? errors.amount.message : ''} />

                                                </Grid>
                                            </Grid>

                                        </AccordionDetails>
                                    </Accordion>

                                    {/* =============================salesperson=========================== */}
                                    <Accordion
                                        expanded={salespersonFields.length !== 0}
                                        defaultExpanded={state.operation == "add" ? false : true}
                                        style={{ margin: "20px 0px" }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreOutlined />}
                                            aria-controls="panel3a-content"
                                            id="panel3a-header"
                                            style={{ display: "flex", alignItems: 'center' }}
                                        >
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <h3>Salesperson</h3>
                                                {!salespersonFields.length && <AddCircleOutlineIcon fontSize='medium'
                                                    color='primary'
                                                    onClick={() => {
                                                        salespersonAppend
                                                            ({
                                                            })
                                                    }} />}
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={2}>


                                                {salespersonFields.map((value: any, index: number) => {
                                                    return <>
                                                        <Grid item xs={12} md={12} lg={12}>
                                                            <h4>Salesperson {index + 1}</h4>
                                                        </Grid>
                                                        {operation !== 'create' &&
                                                            <Input
                                                                required={false}
                                                                type="hidden"
                                                                label="Sale Person Details"
                                                                register={register(`salesperson.${index}.sales_project_id`)}
                                                                autoFocus={false}
                                                                style={{ display: 'none' }}
                                                            />
                                                        }
                                                        <Grid item xs={4} md={4} lg={4}>
                                                            <Controller
                                                                control={control}
                                                                name={`salesperson.${index}.sale_person_id`}
                                                                render={({ field: any }) => (
                                                                    <DropDownComponent
                                                                        value={getValues(`salesperson.${index}.sale_person_id`)}
                                                                        disabled={operation === 'view'}
                                                                        label='Select Salesperson*'
                                                                        menuItems={saleperson}
                                                                        showCreateOption={true}
                                                                        popupFunction={(e: any) => {
                                                                            salespersonTaskRef.current.handleClickSalespersonDialogOpen()
                                                                        }}
                                                                        valueKey='sale_person_id'
                                                                        showNameKey='name'
                                                                        onChange={(e: any) => {
                                                                            setValue(`salesperson.${index}.sale_person_id`, e.target.value);
                                                                        }}
                                                                        error={errors?.salesperson?.[index]?.sale_person_id ? true : false}
                                                                    />
                                                                )}
                                                            />

                                                            <Errormessage errormessage={errors?.salesperson?.[index]?.sale_person_id ? errors?.salesperson?.[index]?.sale_person_id?.message : ''} />
                                                        </Grid>
                                                        <Grid item xs={4} md={4} lg={4} style={{ marginTop: "-15px" }}>
                                                            <Input
                                                                disabled={operation === 'view'}
                                                                required={false}
                                                                type="number"
                                                                label="Salesperson Share in %*"
                                                                register={register(`salesperson.${index}.share`)}
                                                                onChange={(e: any) => {
                                                                    setValue(`salesperson.${index}.share`, e.target.value);
                                                                    calculateShare();
                                                                }}
                                                                error={errors?.salesperson?.[index]?.share ? true : false}
                                                            />
                                                            <Errormessage errormessage={errors?.salesperson?.[index]?.share ? errors?.salesperson?.[index]?.share?.message : ''} />
                                                        </Grid>

                                                        <Grid item xs={4} md={4} lg={4}>
                                                             <Controller
                                                                control={control}
                                                                name={`salesperson.${index}.amount`}
                                                                render={({ field: any }) => (
                                                                    <FormControl className='col' focused variant='outlined' style={{ width: "100%" }}>
                                                                        <InputLabel className='mText' style={{ backgroundColor: "white", color: "grey" ,padding:"0px 20px 0px 10px"}}>Salesperson Amount*</InputLabel>
                                                                        <NumberFormat
                                                                            autoFocus={false}
                                                                            // disabled={true}
                                                                            disabled={operation === 'view'}

                                                                            required={true}
                                                                            thousandsGroupStyle="lakh"
                                                                            displayType='input'
                                                                            value={getValues(`salesperson.${index}.amount`)}
                                                                            prefix={tempPrefillValues.currency ? `${tempPrefillValues.currency} ` : ""}
                                                                            //    allowNegative={false}
                                                                            customInput={TextField}
                                                                            InputProps={{

                                                                                style: {
                                                                                    borderRadius: "25px",

                                                                                    // marginTop:"10px",
                                                                                }

                                                                            }}
                                                                            thousandSeparator={true}
                                                                            autoComplete='off'
                                                                            onValueChange={(values: any) => {
                                                                                const { formattedValue, value } = values;
                                                                                setValue(`salesperson.${index}.amount`, value)
                                                                                // calculateShare();
                                                                                calculatePercentile()
                                                                            }}

                                                                            error={errors.amount ? true : false}
                                                                        />
                                                                    </FormControl>
                                                                )}

                                                            />
                                                            <Errormessage errormessage={errors?.salesperson?.[index]?.amount ? errors?.salesperson?.[index]?.amount?.message : ''} />
                                                        </Grid>

                                                        <Grid item xs={3} md={3} lg={3}>
                                                            {operation !== 'view' && <MuiDelete
                                                                fontSize='large'
                                                                color="error"
                                                                onClick={(e: any) => {
                                                                    if (operation !== 'create' && getValues(`salesperson.${index}.sales_project_id`)) {
                                                                        let delete_salesperson = getValues(`salesperson.${index}`)
                                                                        setDeleted_salesperson([...deleted_salesperson, delete_salesperson])
                                                                        salespersonRemove(index)
                                                                        calculateShare();
                                                                        // calculateShare()
                                                                        // deleteSalesPerson(index)
                                                                    }
                                                                    else {
                                                                        salespersonRemove(index)
                                                                        calculateShare();
                                                                    }
                                                                }} />}

                                                            {operation !== 'view' && index === salespersonFields?.length - 1 && <AddCircleOutlineIcon
                                                                color='primary'
                                                                fontSize='large'
                                                                onClick={() => {
                                                                    salespersonAppend
                                                                        ({

                                                                        })
                                                                }} />}
                                                        </Grid>
                                                    </>
                                                })}


                                            </Grid>

                                        </AccordionDetails>
                                    </Accordion>

                                    {/* ==================================department=========================== */}
                                    <Accordion
                                        expanded={departmentFields.length !== 0}

                                        defaultExpanded={state.operation == "add" ? false : true}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreOutlined />}
                                            aria-controls="panel4a-content"
                                            id="panel4a-header"
                                            style={{ display: "flex", justifyContent: "flex-end" }}
                                        >
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <h3>Department</h3>
                                                {!departmentFields.length && <AddCircleOutlineIcon color='primary' fontSize='medium' onClick={(e: any) => {
                                                    departmentAppend
                                                        ({
                                                            share: "100"
                                                        })
                                                        calculateShare()
                                                }} />}
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={2}>
                                                {departmentFields.map((value: any, index: number) => {
                                                    return <>
                                                        <Grid item xs={12} md={12} lg={12}>
                                                            <h4>Department {index + 1}</h4>
                                                        </Grid>
                                                        <Grid item xs={4} md={4} lg={4}>
                                                            <Controller
                                                                control={control}
                                                                name={`department.${index}.department_id`}
                                                                render={({ field: any }) => (
                                                                    <DropDownComponent
                                                                        disabled={operation === 'view'}
                                                                        label='Department*'
                                                                        menuItems={departments}
                                                                        valueKey='department_id'
                                                                        showNameKey='name'
                                                                        showCreateOption={true}
                                                                        popupFunction={(e: any) => {
                                                                            departmentTaskRef.current.handleClickDepartmentDialogOpen()
                                                                        }}
                                                                        value={getValues(`department.${index}.department_id`)}
                                                                        onChange={(e: any) => {
                                                                            setValue(`department.${index}.department_id`, e.target.value);
                                                                        }}
                                                                        error={errors?.department?.[index]?.department_id ? true : false}
                                                                    />
                                                                )}
                                                            />
                                                            <Errormessage errormessage={errors?.department?.[index]?.department_id ? errors?.department?.[index]?.department_id?.message : ''} />
                                                        </Grid>
                                                        <Grid item xs={4} md={4} lg={4} style={{ marginTop: "-15px" }} >
                                                            <Input
                                                                disabled={operation === 'view'}
                                                                required={false}
                                                                autoFocus={false}
                                                                type="number"
                                                                // autoFocus={false}
                                                                // defaultValue={getValues(`department.${index}.share`)?getValues(`department.${index}.share`):"100"}
                                                                label="Department Share in %*"
                                                                onChange={(e: any) => {
                                                                    setValue(`department.${index}.share`, e.target.value);
                                                                    calculateShare();
                                                                    console.log(getValues('remaining_share'), "-----remain share")
                                                                    // setValue(`department.${(departmentFields.length)}.share`, getValues("remaining_share"))
                                                                }}
                                                                register={register(`department.${index}.share`)}
                                                                error={errors?.department?.[index]?.share ? true : false}
                                                            />
                                                            <Errormessage errormessage={errors?.department?.[index]?.share ? errors?.department?.[index]?.share?.message : ''} />
                                                        </Grid>

                                                        <Grid item xs={4} md={4} lg={4}>
                                                           <Controller
                                                                control={control}
                                                                name={`department.${index}.amount`}
                                                                render={({ field: any }) => (
                                                                    <FormControl className='col' focused variant='outlined' style={{ width: "100%" }}>
                                                                        <InputLabel className='mText' style={{ backgroundColor: "white", color: "grey",padding:"0px 20px 0px 10px" }}>Department Amount*</InputLabel>
                                                                        <NumberFormat
                                                                            autoFocus={false}
                                                                            disabled={operation === 'view'}


                                                                            // disabled={true}
                                                                            required={true}
                                                                            thousandsGroupStyle="lakh"
                                                                            InputProps={{

                                                                                style: {
                                                                                    borderRadius: "25px",
}

                                                                            }}
                                                                            displayType='input'
                                                                            value={getValues(`department.${index}.amount`)}
                                                                            prefix={tempPrefillValues.currency ? `${tempPrefillValues.currency} ` : ""}
                                                                            //    allowNegative={false}
                                                                            customInput={TextField}
                                                                            thousandSeparator={true}
                                                                            autoComplete='off'
                                                                            onValueChange={(values: any) => {
                                                                                const { formattedValue, value } = values;
                                                                                setValue(`department.${index}.amount`, value)
                                                                                // calculateShare();
                                                                                calculatePercentile("department")
                                                                            }}

                                                                            error={errors.amount ? true : false}
                                                                        />
                                                                    </FormControl>
                                                                )}

                                                            />
                                                        </Grid>

                                                        <Grid item xs={3} md={3} lg={3} >
                                                            {operation !== 'view' && <MuiDelete
                                                                fontSize='large'
                                                                color="error"
                                                                onClick={(e: any) => {
                                                                    if (operation !== 'create' && getValues(`department.${index}.sow_department_id`)) {
                                                                        let delete_department = getValues(`department.${index}`)
                                                                        setDeleted_department([...deleted_department, delete_department])
                                                                        departmentRemove(index);
                                                                    }
                                                                    else departmentRemove(index);
                                                                }}
                                                            />}

                                                            {operation !== 'view' && index === departmentFields.length - 1 && <AddCircleOutlineIcon
                                                                color='primary' fontSize='large' onClick={(e: any) => {
                                                                    // remainingShare()
                                                                    departmentAppend
                                                                        ({
                                                                            share: parseInt(getValues("remaining_share"))
                                                                        })
                                                                    console.log(getValues(`department`), "------departmentssss=====")
                                                                    calculateShare()
                                                                }} />}
                                                        </Grid>

                                                        {operation !== 'create' &&
                                                            <Input
                                                                required={false}
                                                                type="hidden"
                                                                label="Department Details"
                                                                register={register(`department.${index}.sow_department_id`)}
                                                                autoFocus={false}
                                                                style={{ display: 'none' }}
                                                            />
                                                        }
                                                    </>
                                                })}
                                            </Grid>

                                        </AccordionDetails>
                                    </Accordion>
                                    {/* ================================milestone============================= */}
                                    <Accordion
                                        expanded={milestoneFields.length !== 0}

                                        defaultExpanded={state.operation == "add" ? false : true}
                                        style={{ margin: "20px 0px" }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreOutlined />}
                                            aria-controls="panel5a-content"
                                            id="panel5a-header"
                                            style={{ display: "flex", justifyContent: "flex-end" }}
                                        >
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <h3>Milestone</h3>
                                                {!milestoneFields?.length && <AddCircleOutlineIcon
                                                    color='primary'
                                                    fontSize='medium' onClick={(e: any) => {
                                                        milestoneAppend({
                                                            type: 8
                                                        })
                                                    }} />}
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={2}>


                                                {milestoneFields.map((value: any, index: number) => {
                                                    setValue('index', index)
                                                    return <>
                                                        <Grid item lg={12} md={12} xs={12}>
                                                            <h4>Milestone {index + 1}</h4>
                                                        </Grid>
                                                        <Grid item xs={6} md={6} lg={6}>
                                                            <Input
                                                                disabled={operation === 'view'}
                                                                // required={true}
                                                                type="text"
                                                                label="Milestone Description*"
                                                                register={register(`milestone.${index}.name`)}
                                                                error={errors?.milestone?.[index]?.name ? true : false}
                                                            />
                                                            <Errormessage errormessage={errors?.milestone?.[index]?.name ? errors?.milestone?.[index]?.name?.message : ''} />
                                                        </Grid>

                                                        <Grid item xs={6} md={6} lg={6} style={{ marginTop: "15px" }}>
                                                            <Controller
                                                                control={control}
                                                                name={`milestone.${index}.type`}
                                                                render={({ field: any }) => (
                                                                    <DropDownComponent
                                                                        disabled={operation === 'view'}
                                                                        label='Milestone Type*'
                                                                        menuItems={dummyMilestoneStatus}
                                                                        valueKey='id'
                                                                        showNameKey='type'
                                                                        onChange={(e: any) => {
                                                                            setValue(`milestone.${index}.type`, e.target.value)
                                                                        }}
                                                                        error={errors?.milestone?.[index]?.type ? true : false}
                                                                        value={getValues(`milestone.${index}.type`)}
                                                                    />
                                                                )}
                                                            />
                                                            <Errormessage errormessage={errors?.milestone?.[index]?.type ? errors?.milestone?.[index]?.type?.message : ''} />

                                                        </Grid>

                                                        <Grid item xs={6} md={6} lg={6}>
 <Controller
                                                                control={control}
                                                                name={`milestone.${index}.amount`}

                                                                render={({ field: any }) => (
                                                                    <FormControl focused className="col" variant='outlined' style={{ width: "100%" }}>
                                                                        <InputLabel className='mText' style={{ backgroundColor: "white", color: "grey",padding:"0px 20px 0px 10px" }}>Milestone Amount*</InputLabel>
                                                                        <NumberFormat
                                                                            autoFocus={false}
                                                                            disabled={operation === 'view'}
                                                                            required={true}
                                                                            InputProps={{

                                                                                style: {
                                                                                    borderRadius: "25px",

                                                                                    // marginTop:"10px",
                                                                                }

                                                                            }}
                                                                            thousandsGroupStyle="lakh"
                                                                            displayType='input'
                                                                            value={getValues(`milestone.${index}.amount`)}
                                                                            prefix={tempPrefillValues.currency ? `${tempPrefillValues.currency} ` : ""}
                                                                            allowNegative={false}
                                                                            customInput={TextField}
                                                                            thousandSeparator={true}
                                                                            autoComplete='off'
                                                                            onValueChange={(values: any) => {
                                                                                const { formattedValue, value } = values;
                                                                                setValue(`milestone.${index}.amount`, value)
                                                                                // calculateShare();
                                                                            }}
                                                                            error={errors.amount ? true : false}
                                                                        />
                                                                    </FormControl>
                                                                )}
                                                            />

                                                            <Errormessage errormessage={errors?.milestone?.[index]?.amount ? errors?.milestone?.[index]?.amount?.message : ''} />
                                                        </Grid>


                                                        <Grid item xs={6} md={6} lg={6}>
                                                            <Input
                                                                style={{ marginTop: "-5px" }}
                                                                disabled={operation === 'view'}
                                                                required={false}
                                                                type="text"
                                                                label="Comments"
                                                                register={register(`milestone.${index}.comments`)}
                                                                autoFocus={false}
                                                                maxRows={3}
                                                            />
                                                        </Grid>




                                                        <Grid item xs={6} md={6} lg={6}>
                                                            <Controller
                                                                control={control}
                                                                name={`milestone.${index}.invoice_date`}
                                                                render={({ field: any }) => (
                                                                    <MyDatepicker
                                                                        disabled={operation === 'view'}
                                                                        label='Invoice Date*'
                                                                     
                                                                        value=
                                                                        {
                                                                            getValues(`milestone.${index}.invoice_date`) ?
                                                                                getValues(`milestone.${index}.invoice_date`) :
                                                                                updateDate(index)
                                                                        }
                                                                        onChange={(newValue: any) => {
                                                                            let d = new Date(newValue);
                                                                            let dayCount = 0;

                                                                            dummyPaymentTermsInDays.map((value: any) => {
                                                                                if (value.id === getValues('payment_terms')) {
                                                                                    dayCount = value.value;
                                                                                }
                                                                            })
                                                                            d.setDate(d.getDate() + dayCount);
                                                                            let newDueDate = d.toISOString().split('T')[0];
                                                                            console.log(newDueDate)

                                                                            setValue(`milestone.${index}.invoice_date`, newValue.toISOString().split('T')[0]);
                                                                            setValue(`milestone.${index}.due_date`, newDueDate)
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>

                                                        <Grid item xs={6} md={6} lg={6}>
                                                            <Controller
                                                                control={control}
                                                                name={`milestone.${index}.due_date`}
                                                                render={({ field: any }) => (
                                                                    <MyDatepicker
                                                                        disabled={operation === 'view'}
                                                                        label='Due Date*' 
                                                                      
                                                                        value={getValues(`milestone.${index}.due_date`) ? getValues(`milestone.${index}.due_date`)
                                                                            :
                                                                            setValue(`milestone.${index}.due_date`, new Date().toISOString().split('T')[0])}
                                                                        onChange={(newValue: any) => {
                                                                            setValue(`milestone.${index}.due_date`, newValue.toISOString().split('T')[0]);
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                            <Errormessage errormessage={errors?.milestone?.[index]?.due_date ? errors?.milestone?.[index]?.due_date?.message : ''} />
                                                        </Grid>

                                                        <Grid item xs={12} md={12} lg={12}>
                                                            {operation !== 'view' && <MuiDelete
                                                                fontSize='large'
                                                                color="error"
                                                                onClick={(e: any) => {
                                                                    if (operation !== 'create' && getValues(`milestone.${index}.milestone_id`)) {
                                                                        let delete_milestone = getValues(`milestone.${index}`)
                                                                        setDeleted_milestone([...deleted_milestone, delete_milestone])
                                                                        milestoneRemove(index)
                                                                    }
                                                                    else {
                                                                        milestoneRemove(index)
                                                                    }
                                                                }} />}

                                                            {operation !== 'view' && index === milestoneFields.length - 1 && <AddCircleOutlineIcon
                                                                color='primary'
                                                                fontSize='large' onClick={(e: any) => {
                                                                    milestoneAppend({

                                                                    })
                                                                }} />}
                                                        </Grid>

                                                        {operation !== 'create' &&
                                                            <Input
                                                                required={false}
                                                                type="hidden"
                                                                label="Milestone Id"
                                                                register={register(`milestone.${index}.milestone_id`)}
                                                                autoFocus={false}
                                                                style={{ display: 'none' }}
                                                            />
                                                        }
                                                    </>
                                                })}
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>



                                    <Projectdialog
                                        ref={projectTaskRef}
                                        customerData={customers}
                                        customerName={getValues("customer_detail")}
                                        updateProjects={(newData: any) => {
                                            console.log(newData);
                                            setAllProjects([...allProjects, { ...newData }])
                                            setProjects([...projects, { ...newData }]);
                                            setValue('project_detail', newData.project_id);
                                            setTempPrefillValues({ ...tempPrefillValues, project_detail: newData.project_id })
                                        }}
                                        customerId={tempPrefillValues.customer_detail}
                                    />
                                    <CustomerDialog
                                        ref={customerTaskRef}
                                        customerData={customers}
                                        updateCustomers={(newData: any) => {
                                            setCustomers([...customers, { ...newData }])
                                            setValue('customer_detail', newData.customer_id);
                                            let currency = newData.billing_currency;
                                            setTempPrefillValues({ ...tempPrefillValues, customer_detail: newData.customer_id, currency: getCurrencySymbol(currency) })
                                        }}
                                    />

                                    <Salespersondialog
                                        ref={salespersonTaskRef}
                                        updateSalespersonList={(newData: any) => {
                                            setSaleperson([...saleperson, { ...newData }])
                                        }}

                                    />

                                    <Departmentdialog
                                        ref={departmentTaskRef}
                                        updateDepartmentList={(newData: any) => {
                                            setDepartments([...departments, { ...newData }])
                                        }}
                                    />

                                    <Grid container style={{ marginTop: "40px", gap: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Button
                                            variant="outlined"
                                            style={{ pointerEvents: 'visible', width: "12%", borderRadius: "25px", fontWeight: 600 }}
                                            onClick={() =>
                                                handleBack()
                                            }
                                            size='large'
                                            // startIcon={<ArrowBackIcon />} 
                                            btnText={'Back'} type={'button'} />

                                        <Btn
                                            type="submit"
                                            variant="contained"
                                            size='large' btnText={operation === 'edit' ? 'Update' : 'Save'} disable={operation == 'view'} />
                                    </Grid>
                                </div>
                            </form>
                        </Container>
                    </div>
                </div>


            </div>
        </Loader>
    </React.Fragment >
}

export default AddStatementOfWork;