import React, { useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
// import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { AccountCircle, AssessmentOutlined, PersonOutlined } from '@mui/icons-material';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
// --------------------ICONS--------------------------
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { useNavigate } from 'react-router-dom';
import { showToast } from '../../components/Toast/Toast';
import useLocalStorage from '../../hooks/localStorage';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import AlertDialog from '../../components/Alertdialog/Dialog';
import { makeStyles } from '@mui/styles';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined';
import { styled, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SidebarPoper from './SidebarPoper';
import './Sidebar.scss'
const drawerWidth = 230;

const Main = styled('main', { shouldForwardProp: (prop: any) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }: any) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop: any) => prop !== 'open',
})<AppBarProps>(({ theme, open }: any) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: "flex",
  },
  selected: {
    "&.Mui-selected": {
      width: "auto !important",
      // backgroundColor: "#1976d2 !important",
      background: "linear-gradient(93deg, rgba(64,172,73,1) 0%, rgba(0,126,191,1) 100%) !important ",
      margin: "0px 4px",
      color: "white !important",
      borderRadius: "20px !important",


    },
    ' &:hover':
    {
      // width: "auto",
      backgroundColor: "#f2f2f2 !important",
      borderRadius: "20px !important"
    }
  }
}
))




const Sidebar = () => {
  const theme = useTheme()
  const [open, setOpen] = React.useState(true);
  const [openReportsPopper, setOpenReportsPopper] = useState(false)
  const handleDrawerOpen = () => {

    setOpen(true);
  };

  const handleDrawerClose = () => {


    setOpen(false);
  };
  const loginData = useLocalStorage.getItem("loginData")
  const deleteTaskRef: any = useRef();
  const navigate = useNavigate();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isLocationSelected = (path: any, type?: any) => {
    return type !== 0 && window.location.pathname.includes(path)
  }

  const onConfirm = () => {
    useLocalStorage.removeItem('loginData');
    setAnchorEl(null);
    showToast('SUCCESS', 'Logout sucessfully')
    navigate('/signin');
  }

  const onDiscard = () => {
    deleteTaskRef.current.handleClose();
  }

  return (
    <div className='Sidebar'>

    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: 1500 }} style={{
        background: "linear-gradient(93deg, rgba(64,172,73,1) 0%, rgba(0,126,191,1) 100%)"
      }}>
        <Toolbar>
          <Typography variant="h5" noWrap component="div">
            InvoiceTracker
          </Typography>
          <div style={{ position: "absolute", right: 0 }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              disableRipple={true}
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle fontSize='large' />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => {
                deleteTaskRef.current.handleClickOpen();
              }}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
            <List className='Sidebar__list'>



              <ListItem button onClick={(e: any) => {
                navigate('/customers')
              }}
                className={classes.selected}
                selected={isLocationSelected('/customers')}
              >
                <ListItemIcon>
                  <PeopleAltOutlinedIcon style={{ color: isLocationSelected('/customers') ? 'white' : '' }} />
                </ListItemIcon>
                <ListItemText primary={'Customers'}
                />
              </ListItem>


              <ListItem button onClick={(e: any) => {
                navigate('/statement-of-work')
              }}
                className={classes.selected}
                selected={isLocationSelected('/statement-of-work', { state: true })}
              >
                <ListItemIcon >
                  <AccountTreeOutlinedIcon style={{ color: isLocationSelected('/statement-of-work') ? 'white' : '' }} />
                </ListItemIcon>
                <ListItemText primary={'SOW'} />
              </ListItem>

              <ListItem button onClick={(e: any) => {
                navigate('/invoices')
              }}
                className={classes.selected}
                selected={isLocationSelected('/invoices')}
              >
                <ListItemIcon>
                  <SummarizeOutlinedIcon style={{ color: isLocationSelected('/invoices') ? 'white' : '' }} />
                </ListItemIcon>
                <ListItemText primary={'Invoices'}

                />
              </ListItem>

              <ListItem button onClick={(e: any) => {
                navigate('/users', { state: true })
              }} style={{ display: loginData?.user_type < 3 ? 'flex' : 'none' }}
                className={classes.selected}
                selected={isLocationSelected('/users')}
              >
                <ListItemIcon>
                  <PersonOutlined style={{ color: isLocationSelected('/users') ? 'white' : '' }} />
                </ListItemIcon>
                <ListItemText primary={'Users'}
                />
              </ListItem>


              <ListItem button onClick={(e: any) => {
                navigate('/salesperson')
              }}
                className={classes.selected}
                selected={isLocationSelected('/salesperson')}
              >
                <ListItemIcon>
                  <PeopleAltOutlinedIcon style={{ color: isLocationSelected('/salesperson') ? 'white' : '' }} />
                </ListItemIcon>
                <ListItemText primary={'Salesperson'}
                />
              </ListItem>


              <ListItem button onClick={(e: any) => {
                navigate('/projects')
              }}
                className={classes.selected}
                selected={isLocationSelected('/projects')}
              >
                <ListItemIcon>
                  <ArticleOutlinedIcon style={{ color: isLocationSelected('/projects') ? 'white' : '' }} />
                </ListItemIcon>
                <ListItemText primary={'Projects'}
                />
              </ListItem>


              <ListItem button onClick={(e: any) => {
                navigate('/departments')
              }}
                className={classes.selected}
                selected={isLocationSelected('/departments')}
              >
                <ListItemIcon>
                  <MapsHomeWorkOutlinedIcon style={{ color: isLocationSelected('/departments') ? 'white' : '' }} />
                </ListItemIcon>
                <ListItemText primary={'Departments'}
                />
              </ListItem>




              {/* -----------------------------------------------------------reports----------------------------------------------------- */}


              <ListItem button onClick={(e: any) => {
                setOpenReportsPopper(!openReportsPopper)
                // navigate('/reports')
              }}
                className={classes.selected}
                selected={isLocationSelected('/reports') || isLocationSelected("/department-report") || isLocationSelected("/all-report")}
              >
                <ListItemIcon>
                  <AssessmentOutlined style={{ color: isLocationSelected('/reports') || isLocationSelected("/department-report") || isLocationSelected("/all-report") ? 'white' : '' }} />
                </ListItemIcon>
                <ListItemText primary={'Reports'}
                />
              </ListItem>

              <SidebarPoper
                content={<div className="popper-content">
                  <List component="div" className='Sidebar__subList' disablePadding>
                    <ListItem button
                      onClick={() => {
                        // LinkedInOpEmitter()
                        navigate('/reports')
                        setOpenReportsPopper(false)
                      }}
                      className={classes.selected}
                      selected={isLocationSelected("reports")}
                    >
                      {/* <ListItemIcon className="Sidebar__listIcon">
                       
                      </ListItemIcon> */}
                      <ListItemText primary="Client Reports" />
                    </ListItem>
                    <ListItem button
                      onClick={() => {
                        navigate('/department-report')
                        setOpenReportsPopper(false)


                      }}
                      className={classes.selected}
                      selected={isLocationSelected("/department-report")}
                    >

                      <ListItemText primary="Department Reports" />

                    </ListItem>


                    <ListItem button
                      onClick={() => {
                        navigate('/all-report')
                        setOpenReportsPopper(false)


                      }}
                      className={classes.selected}
                      selected={isLocationSelected("/all-report")}
                    >

                      <ListItemText primary="Cumulative Reports" />

                    </ListItem>


                  </List>
                </div>}
                open={openReportsPopper}
                placement="right"
                onClose={() => setOpenReportsPopper(false)}
              >
                <div></div>
              </SidebarPoper>

              {/* <ListItem button onClick={(e: any) => {
              navigate('/invoice-reports')
            }} 
            className={classes.selected}
            selected={isLocationSelected('/invoice-reports')}
            >
              <ListItemIcon>
                <AssessmentOutlined style={{color:isLocationSelected('/invoice-reports')?'white':''}}/>
              </ListItemIcon>
              <ListItemText primary={'Invoice Reports'}
              />
            </ListItem> */}
            </List>

        </Box>
      </Drawer>

      <React.Suspense fallback={""}>
        <AlertDialog
          ref={deleteTaskRef}
          description={"Are you sure you want to logout?"}
          title={"Logout"}
          confirm={"Confirm"}
          discard={"Discard"}
          onConfirm={onConfirm}
          onDiscard={onDiscard}
        ></AlertDialog>
      </React.Suspense>

    </Box>
    </div>

  );
}

export default Sidebar;