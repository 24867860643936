import React, { useEffect } from 'react';
import './App.scss';
import { Routes, Route, useNavigate, Navigate , useLocation } from 'react-router-dom';
import SignIn from './containers/Auth/login/Login';
import Forgotpassword from './containers/Auth/forgotpassword/Forgotpassword';
import Signup from './containers/Auth/signup/Signup';
import Resetpassword from './containers/Auth/resetpassword/Resetpassword';
import Notfound from './containers/Notfound-404/Notfound';
import Toast from './components/Toast/Toast';
import Users from './containers/Users/Users';
import useLocalStorage from './hooks/localStorage';
import Sidebar from './containers/Sidebar/Sidebar';
import AddStatementOfWork from './containers/Statementofwork/AddStatementofwork';
import Reports from './containers/Reports/Report';
import Salesperson from './containers/Salesperson/Salesperson';
import Customers from './containers/Customers/Customers';
import Projects from './containers/Projects/Projects';
import Departments from './containers/Departments/Department';
import StatementofWork from './containers/Statementofwork/StatementOfWork';
import Invoice from './containers/Invoices/Invoice';
import CreateInvoice from './containers/Invoices/CreateInvoices';
import DepartmentReport from './containers/Reports/DepartmentReport';
import CumulativeReports from './containers/Reports/CumulativeReports';
const exclusionArray = [
  "/signin",
  "/forgot-password",
  "/reset-password",
];

const App = () => {
  const loginData = useLocalStorage.getItem("loginData")
  const location = useLocation();
  const navigate = useNavigate()

  const isHeaderSidemenuVisible = exclusionArray.indexOf(location.pathname) < 0;
  useEffect(() => {
    window.addEventListener("popstate", e => {
      // Nope, go back to your page
      const loginData =useLocalStorage.getItem('loginData');
      if(!loginData && window.location.pathname != '/reset-password'){
          navigate('/signin')
          window.location.reload()
      }
    });
  }, [window])
  return <React.Fragment>
    {/* --------------SHOW HEADER IF PERSON IS LOGIN----------- */}

    {isHeaderSidemenuVisible?<Sidebar/>:null}
    <Routes>
      <Route path="/signin" element={<SignIn />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/forgot-password" element={<Forgotpassword />} />
      <Route path="/reset-password" element={<Resetpassword />} />
      <Route path="/signup" element={<Signup/>} />
      <Route path="/" element={<Navigate to="/signin" />} />
      <Route path="/users" element={<Users/>}/>
      <Route path="/statement-of-work" element={<StatementofWork/>}/>
      <Route path="/invoices" element={<Invoice/>}/>
      <Route path="/add/invoices" element={<CreateInvoice/>}/>
      <Route path="/reports" element={<Reports/>}/>
      <Route path="department-report" element={<DepartmentReport/>}/>
      <Route path="all-report" element={<CumulativeReports/>}/>
      <Route path="/salesperson" element={<Salesperson/>}/>
      <Route path="/customers" element={<Customers/>}/>
      <Route path="/projects" element={<Projects/>}/>
      <Route path="/departments" element={<Departments/>}/>
      <Route path="/add/statement-of-work" element={<AddStatementOfWork/>}/>
      <Route path="*" element={<Notfound />} />
    
    </Routes>
    {/* ---------TOAST--------- */}
    <Toast />
  </React.Fragment>
}

export default App;
