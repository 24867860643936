import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Menu,
  MenuItem,
} from "@mui/material";
import React, {
  useImperativeHandle,
  useState,
  forwardRef,
  Suspense,
} from "react";
// import {  Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material';
// import React, { useImperativeHandle, useState, forwardRef, Suspense } from 'react';
//---------------------------------------------------
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import SalespersonEndpoints from "./Salesperson.endpoints";
import DropDownComponent from "../../components/Select/Select";
import Errormessage from "../../components/Errormessage/Errormessage";
import { showToast } from "../../components/Toast/Toast";
import useLocalStorage from "../../hooks/localStorage";
import useApiService from "../../services/api.service";
import Input from "../../components/Input/Input";
import Loader from "../../components/Loader/Loader";
import { useRef, useEffect } from "react";
import Datatable from "../../components/Datatable/Datatable";
import EditIcon from "../../assets/images/editIcon.svg";
import DeleteIcon from "../../assets/images/deleteIcon.svg";
import AlertDialog from "../../components/Alertdialog/Dialog";
import Btn from "../../components/Button/Button";
const styles = {
  container: {
    borderRadius: "20px",
    minHeight: "80vh",
    padding: "3vh",
    width: "auto",
    margin: "100px 40px 40px 260px",
    backgroundColor: "white",
  },
  dialogcontainer: { padding: "15px", width: "600px" },
};

const iconDivStyles = {
  display: "flex",
  cursor: "pointer",
};

const Salesperson = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const ApiService = useApiService();
  const loginData = useLocalStorage.getItem("loginData");
  const [operation, setOperation] = useState("");
  const deleteTaskRef: any = useRef();
  const [salesperson, setSalesperson]: any = useState([]);
  const [clickedRow, setClickedRow]: any = useState({});
  const [submission, setSubmission] = useState(false);

  const getSalesperson = async () => {
    setLoading(true);

    const reqBody: any = {};

    const header = {
      access_token: loginData?.access_token,
    };
    const reqData = {
      reqBody: reqBody,
      headers: header,
    };

    await ApiService.get(SalespersonEndpoints.salesperson(reqData))
      .then((res: any) => {
        setSalesperson(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // ---------------validation schema-----------------
  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required("This field is required"),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    getValues,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    getSalesperson();
  }, []);

  const submit = async (data: any) => {
    // setLoading(true);
    setSubmission(true);
    console.log("-----------FORM SUBMITTED----------");
    console.log(data);

    const reqBody: any = {
      name: data.name,
    };
    if (operation === "edit") {
      reqBody.sale_person_id = clickedRow?.sale_person_id;
    }
    const header = {
      access_token: loginData?.access_token,
    };
    const reqData = {
      reqBody: reqBody,
      headers: header,
    };
    await ApiService[operation === "edit" ? "put" : "post"](
      SalespersonEndpoints.salesperson(reqData)
    )
      .then((res: any) => {
        showToast("SUCCESS", res.message);
        getSalesperson();
        resetFields();
        setOpen(false);
      })
      .catch((err: any) => {
        console.log(err);
        showToast("ERROR", err.message);
      })
      .finally(() => {
        // setLoading(false);
        setSubmission(false);
      });
  };

  const resetFields = () => {
    reset({
      name: "",
    });
    setOperation("");
  };

  const TableIcons = (row: any) => {
    const handleEdit = (clickedRow: any) => {
      console.log("------------HANDLE EDIT IS CALLED----------");
      setValue("name", clickedRow?.name);
      setClickedRow(clickedRow);
      setOperation("edit");
      setOpen(true);
    };

    const handleDelete = (clickedRow: any) => {
      console.log("------------HANDLE DELETE IS CALLED----------");
      setClickedRow(clickedRow);
      deleteTaskRef.current.handleClickOpen();
    };

    return (
      <>
        <div style={iconDivStyles}>
          <div>
            <img
              src={EditIcon}
              onClick={() => {
                handleEdit(row.row);
              }}
              className="icon_images"
            />
          </div>
          <div>
            <img
              src={DeleteIcon}
              className="icon_images"
              onClick={() => {
                handleDelete(row.row);
              }}
            />
          </div>
        </div>
      </>
    );
  };

  var i = 0;
  const [show, setShow] = useState(false);
  const [projectNames, setProjectNames]: any = useState([]);
  const [salesmanName,setSalesmanName] = useState();
  const handleMenuClose = () => {
    setShow(false);
  };
  const handleProject = (prop: any, event: React.MouseEvent<HTMLElement>) => {
    console.log(prop, "props");
    if (prop.total_projects > 0) {
      {
        let temp: any[] = [];
        prop.project_name.map((data: any, index: any) => {
          temp.push(data);
          setValue("sales_name",prop.name)
          // setSalesmanName(prop.name);
          setProjectNames(temp);
          setShow(true);

        });
      }
    } else {
      setValue("sales_name",prop.name)
      setProjectNames(["None"]);
      setShow(true);
    }
  };

  const HandleProjectClick = (prop: any) => {
    console.log(prop, "Handle roe");
    return (
      <>
        <div>
          <div
            id="basic-button"
            style={{ position: "relative", cursor: "pointer" }}
            onClick={(event) => {
              handleProject(prop.data, event);
            }}
          >
            {prop.data.total_projects}
          </div>
          <div></div>
        </div>
      </>
    );
  };
  const columns: any = [
    {
      name: "Name",
      selector: "name",
      wrap: true,
      minWidth: "125px",
    },
    {
      name: "Total Projects",
      wrap: true,
      minWidth: "125px",
      style:{marginLeft:"80px"},
      cell: (data: any) => (
        <div>
          <HandleProjectClick data={data} />
        </div>
      ),
    },
    {
      name: "Actions",
      allowOverflow: true,
      button: true,
      selector: "is_active",
      cell: (row: any) => (
        <div>
          <TableIcons row={row} />
        </div>
      ),
    },
  ];

  const onConfirm = async () => {
    console.log(clickedRow);
    const reqBody = {
      sale_person_id: clickedRow?.sale_person_id,
    };

    const header = {
      access_token: loginData?.access_token,
    };

    const reqData = {
      reqBody: reqBody,
      headers: header,
    };
    await ApiService.delete(SalespersonEndpoints.salesperson(reqData)).then(
      (res: any) => {
        deleteTaskRef.current.handleClose();
        showToast("SUCCESS", res.message);
        getSalesperson();
      }
    );
  };

  const onDiscard = () => {
    deleteTaskRef.current.handleClose();
  };

  return (
    <React.Fragment>
      <Loader loading={loading}>
        <Container maxWidth={false} style={styles.container} className="Users">
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} className="display__style__1">
              <Btn type={"contained"} onClick={() => {
                  setOpen(true);
                  resetFields();
                }} btnText={"Add New Salesperson"}></Btn>
            </Grid>
            <Dialog
            maxWidth="sm"
          open={show}
          onClose={() => {
            setShow(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div style={styles.dialogcontainer}>
            <DialogTitle
              id="alert-dialog-title"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "0px",
              }}
              
            >
              <p className="dialog_title">
              <b>{getValues("sales_name")?getValues("sales_name"): "-" }</b>
              </p>
            </DialogTitle>
            </div>
            <DialogContent > 
                <DialogContentText id="alert-dialog-description" >
                  <Grid container spacing={1}>
                    <Grid item xs={12} lg={12} md={12}>
                      </Grid>
                      </Grid>
                      <p style={{textAlign:"center"}}>
                      <b>Projects - </b>
                      
                      {projectNames.join(' , ')}
                      </p>
                      </DialogContentText>
                      </DialogContent>

           
            </Dialog>
            <Grid item lg={12} md={12}>
              {/* ------------------LISTING OF USERS------------ */}
              <Datatable
                columns={columns}
                data={salesperson}
                noDataComponent={
                  !loading
                    ? salesperson?.length
                      ? " "
                      : " No records to display "
                    : " "
                }
              />
            </Grid>
          </Grid>
        </Container>

        <Dialog
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div style={styles.dialogcontainer}>
            <DialogTitle
              id="alert-dialog-title"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "0px",
              }}
            >
              <p className="dialog_title">
                {operation === "edit" ? "Edit Salesperson" : "Add Salesperson"}
              </p>
            </DialogTitle>
            <form onSubmit={handleSubmit(submit)}>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Loader loading={submission} />
                  <Grid container spacing={1}>
                    <Grid item xs={12} lg={12} md={12}>
                      <Input
                        // required={true}
                        type="text"
                        label="Salesperson Name*"
                        register={register("name")}
                        // autoFocus={true}
                        onChange={(e: any) => {
                          setValue("name", e.target.value);
                        }}
                        error={errors.name ? true : false}
                      />
                      <Errormessage
                        errormessage={errors.name ? errors.name.message : ""}
                      />
                    </Grid>
                  </Grid>
                </DialogContentText>
              </DialogContent>

              <DialogActions
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Btn type="button"
                btnText="Cancel"
                onClick={() => {
                  resetFields();
                  setOpen(false);
                }}
                />
               <Btn type="submit"
                btnText={operation === "edit" ? "Edit" : "Save"}
                />
              </DialogActions>
            </form>
          </div>
        </Dialog>
        <Suspense fallback={""}>
          <AlertDialog
            ref={deleteTaskRef}
            description={"Are you sure you want to delete this Salesperson?"}
            title={"Delete Salesperson"}
            confirm={"Confirm"}
            discard={"Discard"}
            onConfirm={onConfirm}
            onDiscard={onDiscard}
          ></AlertDialog>
        </Suspense>
      </Loader>
    </React.Fragment>
  );
};
export default Salesperson;
