import React, { useEffect, useState } from 'react';
import BackgroundImage from "../../../assets/images/invoices2.svg";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Input from '../../../components/Input/Input';
import Errormessage from '../../../components/Errormessage/Errormessage';
import { Link, useNavigate } from 'react-router-dom';
import { showToast } from '../../../components/Toast/Toast';
import { IconButton, InputAdornment, Paper, TextField } from '@mui/material';
import useApiService from '../../../services/api.service';
import useLocalStorage from '../../../hooks/localStorage';
import LoginEndpoints from './Login.endpoints';
import Loader from '../../../components/Loader/Loader';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Btn from '../../../components/Button/Button';

const theme = createTheme();


const SignIn = () => {

  const [loading, setLoading] = useState(false);
  const ApiService = useApiService();
  const [showPassword, setShowPassword] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    useLocalStorage.setItem('loginData',null)
    setLoading(false);
  }, []);
   //////////toggle show password function////////////
   const handleClickShowPassword = () => setShowPassword(!showPassword)

  const validationSchema = Yup.object().shape({
    email: Yup.string().trim()
      .required('Email is required')
      .email('Email format is invalid'),
    password: Yup.string().trim()
      .required('Password is required')
      .max(20, 'Password must not exceed 20 characters')
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Password must contain at least 8 characters,one uppercase,one number and one special case character"
      )
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = async (data: any) => {
    console.log("------------------FORM SUBMITTED--------------------");
    /////show loader////////
    setLoading(true);

    const reqBody: any =
    {
      email: data.email,
      password: data.password
    };

    const reqData = {
      reqBody: reqBody,
      headers: {},
    };

    await ApiService.post(LoginEndpoints.login(reqData)).then((res: any) => {
      useLocalStorage.setItem("loginData", res.data);
      navigate("/customers");
    }).catch((err: any) => {
      showToast('ERROR', err.message);
      /////////set loading to false/////////
    }).finally(() => {
      setLoading(false)
    });
  };
  console.log(errors)

  // ---------------validation schema-----------------

  return (
    <Loader loading={loading}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ThemeProvider theme={theme}>
          <Grid container component="main" sx={{ height: '100vh' }}>
            <CssBaseline />
            <Grid
              item
              xs={false}
              sm={6}
              md={6}
              lg={6}
              sx={{
                backgroundImage: `url(${BackgroundImage})`,
                backgroundRepeat: 'no-repeat',
                objectFit: 'contain',
                backgroundColor: (t) =>
                  t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            />
            <Grid item xs={6} sm={6} md={6} lg={6} component={Paper} elevation={6} square style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Box
                sx={{
                  my: 8,
                  mx: 4,
                  boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                  borderRadius:"20px",
                  padding:"3rem",
                  width:"600px",
                  height:"auto",
                    display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Sign In
                </Typography>
                <Box sx={{ mt: 1 }}>
                  <Grid container spacing={1}>
                    <Grid item md={12} lg={12} xs={12}>
                      <Input
                        // required={true}
                        fullWidth={true}
                        register={register('email')}
                        label="Email*"
                        type="email"
                        autoComplete='on'
                        error={errors.email ? true : false}
                        onChange={(e: any) => {
                          setValue('email', e.target.value);
                        }}

                      />
                    </Grid>
                    <Grid item lg={12} md={12}>
                      <Errormessage
                        errormessage={errors.email?.message}
                      />
                    </Grid>
                    <Grid item lg={12} md={12}>
                      <Input
                        // required={true}
                        fullWidth={true}
                        regiter = {register('password')}
                        label="Password*"
                        autoComplete='off'
                        error={errors.password ? true : false}
                        onChange={(e: any) => {
                          setValue('password', e.target.value);
                        }}
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                          // <-- This is where the toggle button is added.
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                              >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                    <Grid item lg={12} md={12}>
                      <Errormessage
                        errormessage={errors.password?.message}
                      />
                    </Grid>

                    <Grid item lg={3} md={3} style={{margin:"0 auto"}}>
                      <Btn
                        type="submit"
                        variant="contained" btnText={'Sign In'}  />
                        
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs>
                      <Link to="/forgot-password">
                        Forgot password?
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </ThemeProvider>
      </form>
    </Loader>
  );
}

export default SignIn;