import { Accordion, AccordionDetails, AccordionSummary, Button, Chip, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputLabel, Paper, Stack, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import Datatable from '../../components/Datatable/Datatable';
import Loader from '../../components/Loader/Loader';
import './invoices.scss';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircle';

// -------------------ICONS IMAGES--------------
import EditIcon from "../../assets/images/editIcon.svg";
import DeleteIcon from "../../assets/images/deleteIcon.svg";
import useApiService from '../../services/api.service';
import useLocalStorage from '../../hooks/localStorage';
import { showToast } from '../../components/Toast/Toast';
import AlertDialog from '../../components/Alertdialog/Dialog';
import { Suspense } from 'react';
//---------------------------------------------------
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import Errormessage from '../../components/Errormessage/Errormessage';
import Input from "../../components/Input/Input";
import { isConstructorDeclaration, setConstantValue } from 'typescript';
import DropDownComponent from '../../components/Select/Select';
import InvoiceEndpoints from './Invoices.endpoints';
import MyDatepicker from '../../components/Datepicker/Datepicker';
import SummarizeRoundedIcon from '@mui/icons-material/SummarizeRounded';
import DownloadIcon from "../../assets/images/download.svg";
import InvoiceIcon from "../../assets/images/invoice.svg";
import { isAsyncFunction } from 'util/types';
import { AddCircleOutlineSharp, ArrowLeft, ContactMail, ContactSupport, ExpandMoreOutlined, ViewKanban } from '@mui/icons-material';
import Autocomplete from '../../components/Multiselect/Multiselect';
import MuiDelete from '@mui/icons-material/Delete';
import ViewIcon from "../../assets/images/eye.svg";
import Tooltip from '../../components/Tooltip/Tooltip';
import { makeStyles } from '@mui/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import getCurrencySymbol from '../Statementofwork/getCurrencySymbol';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import DatePicker from '@mui/lab/DatePicker'
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Box from "@mui/material/Box";
import { getValue } from '@testing-library/user-event/dist/utils';
import set from 'date-fns/esm/set';
import Invoice from './Invoice';
import { useLocation, useNavigate } from 'react-router-dom';
import Btn from '../../components/Button/Button';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import { subDays } from 'date-fns/esm';
import { ConsoleWriter } from 'istanbul-lib-report';
const styles =
{
    container: { borderRadius: '20px', minHeight: '80vh', padding: "3vh", width: "auto", margin: "100px 40px 40px 260px",backgroundColor:"white" },
    dialogcontainer: { padding: '15px' }
}

const iconDivStyles =
{
    display: 'flex',
    cursor: "pointer",
}

const dummyPaymentTermsInDays =
    [
        { id: 1, name: '7 days', value: 7 },
        { id: 2, name: "15 days", value: 15 },
        { id: 3, name: "30 days", value: 30 },
        { id: 4, name: "45 days", value: 45 },
        { id: 5, name: "60 days", value: 60 },
        { id: 6, name: "90 days", value: 90 },
    ]

const useStyles = makeStyles({
    columnEllipsis: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "block",
    },
});


const invoiceStatusDropdown = [
    { id: 1, name: 'Draft' },
    { id: 2, name: 'Partially Received' },
    { id: 3, name: 'Completed' }
]

const CreateInvoice = () => {
    const classes = useStyles();
    const loginData = useLocalStorage.getItem("loginData")
    const [loading, setLoading] = useState(true);
    //////////////delete Refs///////////
    const deleteTaskRef: any = useRef();
    const deleteSalespersonRef: any = useRef();
    const deleteDepartmentRef: any = useRef();
    const deleteMilestoneRef: any = useRef();
    ////////////////////////////////////
    const [invoiceData, setInvoiceData] = useState([]);
    const [clickedRow, setClickedRow]: any = useState({});
    const [operation, setOperation] = useState('');
    const ApiService = useApiService();
    const [open, setOpen]: any = useState(false);

    const [customers, setCustomers]: any = useState([]);
    const [sows, setSows]: any = useState([]);
    const [sowMilestones, setSowMilestones]: any = useState([]);
    const [salesperson, setSalesperson]: any = useState([]);
    const [departments, setDepartments]: any = useState([]);
    const [deleted_salesperson, setDeleted_salesperson]: any = useState([])
    const [deleted_department, setDeleted_department]: any = useState([])
    const [currency, setCurrency]: any = useState('');
    const {state} = useLocation() as any;
    const navigate = useNavigate();

    const [startDate, setStartDate]:any = useState(new Date());

    const [autoCompleteValues, setAutocompleteValues]: any = useState
        ({
            customer_sows: [],
            customer_milestones: []
        })
    const [dropdownValues, setDropdownValues]: any = useState
        ({
            customer_id: '',
            invoice_status: ''
        })
    // ---------------validation schema-----------------
    const validationSchema = Yup.object().shape({
        customer_id:
            Yup.string()
                .trim()
                .required('This field is required'),
        amount: Yup.number()
            .typeError('Amount must be a number')
            .required("This field is required")
            .min(0, "Please enter a valid amount"),
        due_date: Yup.date()
            .when("invoice_date",
                (invoice_date, Yup) => invoice_date && Yup.min(invoice_date, "Enter a valid end date")),
        salesperson: Yup.array().of(
            Yup.object().shape({
                sales_person_id: Yup.string().required("This field is required"),
                sales_person_share: Yup.number()
                    .typeError('Share must be a number')
                    .required("This field is required")
                    .min(1, "Please enter a valid share %")
                    .max(100, "Please enter a valid share %"),
            })
        ),
        department: Yup.array().of(
            Yup.object().shape({
                department_id: Yup.string().required("This field is required"),
                department_share: Yup.number()
                    .typeError('Share must be a number')
                    .required("This field is required")
                    .min(1, "Please enter a valid share %")
                    .max(100, "Please enter a valid share %")
            })
        ),
        milestone: Yup.array().of(
            Yup.object().shape({
                description: Yup.string().required("This field is required"),
                unit_price: Yup.number()
                    .typeError('Amount must be a number')
                    .required("This field is required")
                    .min(0, "Please enter a valid amount"),
                quantity: Yup.number()
                    .typeError('Quantity must be a number')
                    .required("This field is required")
                    .min(0, "Please enter a valid quantity"),
            })
        )
    })

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        getValues, setValue,
        reset,
        control
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const { fields: milestoneFields, append: milestoneAppend, remove: milestoneRemove } = useFieldArray({ name: "milestone", control });
    const { fields: departmentFields, append: departmentAppend, remove: departmentRemove } = useFieldArray({ name: "department", control });
    const { fields: salespersonFields, append: salespersonAppend, remove: salespersonRemove } = useFieldArray({ name: "salesperson", control });

    const getDropdowndata = async () => {

        setLoading(true);

        const reqBody = {}
        const header = {
            access_token: loginData?.access_token
        }
        const reqData = {
            reqBody: reqBody,
            headers: header
        };

        let tempSalesPerson: any = [];
        let tempDepartments: any = [];
        let tempCustomers: any = [];
        await ApiService.get(InvoiceEndpoints.sow_salesperson(reqData))
            .then((res: any) => {
                tempSalesPerson = res.data;
            }).catch((err: any) => {
                showToast('ERROR', err.message);
            })
        await ApiService.get(InvoiceEndpoints.sow_department(reqData))
            .then((res: any) => {
                tempDepartments = res.data;
            }).catch((err: any) => {
                showToast('ERROR', err.message);
            })
        await ApiService.get(InvoiceEndpoints.customer(reqData))
            .then((res: any) => {
                tempCustomers = res.data;
            }).catch((err: any) => {
                showToast('ERROR', err.message);
            })

        setLoading(false);
        setCustomers(tempCustomers);
        setDepartments(tempDepartments);
        setSalesperson(tempSalesPerson)
    }

    const getParticularCustomerSow = async (customer_id: number) => {
        setLoading(true);

        const reqBody: any =
        {
            customer_id: [customer_id]
        };
        const header =
        {
            access_token: loginData?.access_token
        }
        const reqData = {
            reqBody: reqBody,
            headers: header
        };
        await ApiService.get(InvoiceEndpoints.sow(reqData))
            .then((res: any) => {
                console.log(res.data);
                setSows(res.data);
            }).catch((err: any) => {
                showToast("ERROR", err.message)
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const getMilestones = async (sowArr: any) => {
        setLoading(true);
        const reqBody: any =
        {
            sow_id: sowArr
        };
        const header =
        {
            access_token: loginData?.access_token
        }
        const reqData = {
            reqBody: reqBody,
            headers: header
        };
        await ApiService.get(InvoiceEndpoints.milestone(reqData))
            .then((res: any) => {
                console.log(res.data);
                let tempSowMilestones = res?.data?.map((value: any , index:number) => {
                    console.log(value,"======value=====")
                    return (                       
                        { ...value, new_milestone_name:  value.name  + " (" + value.title + ")" }
                    )
                    
                    
                })
                setSowMilestones([...tempSowMilestones]);
            }).finally(() => {
                setLoading(false);
            });
    }
    
    const getInvoices = async () => {

        setLoading(true);
        const reqBody: any = {};
        const header =
        {
            access_token: loginData?.access_token
        }
        const reqData = {
            reqBody: reqBody,
            headers: header
        };
        await ApiService.get(InvoiceEndpoints.invoice(reqData))
            .then((res: any) => {
                console.log(res.data);
                setInvoiceData(res.data);
            }).finally(() => {
                setLoading(false);
            });
    }
const appendDepartment=(department:any)=>{
console.log(department,"=====department")
department?.map((value:any)=>{
    departmentAppend
    ({
        dep_inv_id: value.dep_inv_id,
        department_id: value.department_id,
        department_share: value.share,
        department_amount: value.amount
    })
})
}
const appendSalesPerson=(sales:any)=>{
    console.log(sales,"=====department")
    sales?.map((value:any,index:number)=>{
        salespersonAppend({
                    sales_person_id: value?.sale_person_id,
                    sales_person_share: parseInt(value?.share),
                })
                setValue(`salesperson.${index}.sales_person_id`,value?.sale_person_id)
                setValue(`salesperson.${index}.sales_person_share`,parseInt(value?.share))
    })
    updateAmount()
    }
    const handleFieldsAppend = (data: any) => {
        data && data.map((milestone: any) => {
            milestoneAppend
                    ({
                        // item_id: value.item_id,
                        milestone_id: milestone.milestone_id,
                        quantity: 1,
                        sow_id: milestone.sow_id,
                        description: milestone.milestone_name,
                        unit_price: milestone.milestone_amount
                    })
                    updateAmount()
     
                appendSalesPerson(milestone.sales_person_share)
                appendDepartment(milestone.departments_shares)
            // if (d.sales_person_share) {
            //     salespersonAppend({
            //         sales_person_id: d?.sales_person_share?.[0]?.sale_person_id,
            //         sales_person_share: parseInt(d?.sales_person_share?.[0]?.share),
            //     })
            //     milestoneAppend
            //         ({
            //             // item_id: value.item_id,
            //             milestone_id: d.milestone_id,
            //             quantity: 1,
            //             sow_id: d.sow_id,
            //             description: d.milestone_name,
            //             unit_price: d.milestone_amount
            //         })
            //     updateAmount()
            // } else {
            //     milestoneAppend
            //         ({
            //             // item_id: value.item_id,
            //             milestone_id: d.milestone_id,
            //             quantity: parseInt(d.quantity),
            //             sow_id: d.sow_id,
            //             description: d.milestone_name,
            //             unit_price: parseInt(d.milestone_amount)
            //         })
            // }
          
        })
        
    }
    const getSalesPerson = async (milestoneArr: any) => {
        setLoading(true)
        const reqBody: any = {
            milestone_id: milestoneArr
        }
        const header = {
            access_token: loginData?.access_token
        }
        const reqData = {
            reqBody: reqBody,
            headers: header
        }
        await ApiService.get(InvoiceEndpoints.invoice_milestone(reqData))
            .then((res: any) => {
                console.log(res.data, "------response for salesperson from milestone-------")
                reset({ milestoneFields: [] })
                reset({ salespersonFields: [] })
                reset ({ departmentFields: []})
                salespersonRemove()
                handleFieldsAppend(res.data)
window.scrollTo(0,0)
                // if(res?.data?.[0]?.sales_person_share){
                //     salespersonAppend({
                //         sales_person_id: res?.data?.[0]?.sales_person_share?.[0]?.sale_person_id,
                //         sales_person_share : res?.data?.[0]?.sales_person_share?.[0]?.share
                //     })

                // }

            })
            .finally(() => {
                setLoading(false)
                console.log(salespersonFields, "==================salesperson------------------------")

            })
    }

    // const handleMilestones = (sows:any)=>{
    //     if(sows){
    //         let tempArrOfSows :any[] = []
    //         sows.map((sow:any)=>{
    //             tempArrOfSows.push(sow.sow_id)
    //         })
    //         getMilestones(tempArrOfSows)
    //     }
    // }
    
    const prefillData = (data: any) => {
        console.log("------------PREFILLDATA FUNCTION CALLED----------");

        setValue('amount', data?.amount);
        setValue('comments', data?.comments);
        setValue("credit_month", data?.credit_month);
        setValue('customer_id', data?.customer_id);

        setValue('customer_sows', data?.temp_data?.temp_sow);
        setValue('customer_milestones', data?.temp_data?.temp_milestones);
        setValue('invoice_date', data?.invoice_date);
        setValue('due_date', data?.due_date);
        setValue('invoice_status', data?.invoice_status);
 getParticularCustomerSow(data?.customer_id)
        setCurrency(getCurrencySymbol(data?.billing_currency))
        
        // data?.billing_currency === 'USD' ? setCurrency('$') : setCurrency('₹');
        setAutocompleteValues
            ({
                customer_sows: data?.temp_data?.temp_sow,
                customer_milestones: data?.temp_data?.temp_milestones
            })

            handleMilestones(data?.temp_data?.temp_sow)

        setDropdownValues({ customer_id: data?.customer_id, invoice_status: data?.invoice_status })

        data?.departments_shares?.map((value: any) => {
            departmentAppend
                ({
                    dep_inv_id: value.dep_inv_id,
                    department_id: value.department_id,
                    department_share: value.department_share,
                    department_amount: value.department_amount
                })
        })

        data?.invoice_item?.map((value: any) => {
            milestoneAppend
                ({
                    item_id: value.item_id,
                    milestone_id: value.milestone_id,
                    quantity: value.quantity,
                    sow_id: value.sow_id,
                    description: value.description,
                    unit_price: value.amount
                })
        })
        data?.sale_person_share?.map((value: any) => {
            salespersonAppend
                ({
                    sales_invoice_id: value.sales_invoice_id,
                    sales_person_id: value.sales_person_id,
                    sales_person_share: value.sales_person_share,
                    sales_person_amount: value.sales_person_amount,
                })
        })

        if (parseInt(data?.invoice_status) === 2) {
            setValue('received_amount', data?.received_amount);
        }
    }
const handleMilestones = (sows:any)=>{
    if(sows){
        let tempArrOfSows :any[] = []
        sows.map((sow:any)=>{
            tempArrOfSows.push(sow.sow_id)
        })
        getMilestones(tempArrOfSows)
    }
}
    const getParticularInvoiceData = async (invoice_id: any) => {

        setLoading(true)
        const reqBody: any =
        {
            invoice_id: invoice_id
        };

        const header =
        {
            access_token: loginData?.access_token
        }
        const reqData = {
            reqBody: reqBody,
            headers: header
        };
        await ApiService.get(InvoiceEndpoints.invoice(reqData))
            .then((res: any) => {
                console.log(res.data);
                prefillData(res.data[0]);
                setOpen(true);
            }).finally(() => {
                setLoading(false);
            });
    }


    useEffect(() => {


        if(state.operation=="add"){
        getDropdowndata();

            setValue('invoice_status', 1)
                setOpen(true);
                setOperation('create')
                resetFields();
           }


        if(state.operation=="edit" && state.invoice_id ){
            setOpen(true);
            setOperation('edit');
            setClickedRow(state.clickedRowData);
            getDropdowndata();
            resetFields();            
            getParticularInvoiceData(state.invoice_id);
        }
        
        if(state.operation=="view"){
            setOpen(true);
            setOperation('view');
            setClickedRow(state.clickedRowData);
            getDropdowndata();
            resetFields();
            getParticularInvoiceData(state.invoice_id);
        }
        
    }, [])

    const onSubmit = async (data: any) => {
        console.log("----------FORM SUBMITTED----------");
        console.log(data);
if(milestoneFields.length === 0){
    showToast("ERROR","Please select a milestone")
    return;
}else if( departmentFields.length ===0){
    showToast("ERROR","Please add a department")
    return;
}else if (salespersonFields.length ==0){
    showToast("ERROR","Please select a milestone")
    return;
}
        //-------------------CHECK FOR RECEIVCED AMOUNT---------//
        if (operation === 'edit' && parseInt(data.invoice_status) === 2) {
            console.log("---------------TESTING-----------")
            console.log(parseInt(data.received_amount))
            console.log(parseInt(data.received_amount) >= data.amount);
            if (!parseInt(data.received_amount) || parseInt(data.received_amount) >= data.amount) {
                showToast('ERROR', 'Please enter a valid received amount')
                return;
            }
        }
        if (data?.department?.length) {
            let Dshare = 0;
            data?.department?.map((value: any) => {
                Dshare += parseInt(value.department_share)
            })
            if (Dshare !== 100) {
                showToast('ERROR', 'Department share must be 100%')
                return;
            }
        }

        if (data?.salesperson?.length) {
            let SalesShare = 0;
            data?.salesperson?.map((value: any) => {
                SalesShare += parseInt(value.department_share)
            })
            if (SalesShare > 100) {
                showToast('ERROR', 'Salesperson share must be less than or equal to 100%')
                return;
            }
        }
        // --------------------------- DUPLICACY CHECK---------------
        let salespersonArr = data?.salesperson?.map((value: any, index: number) => {
            return value.sales_person_id
        })

        let departmentArr = data?.department?.map((value: any, index: number) => {
            return value.department_id;
        })
        // if (salespersonArr.length !== [...Array.from(new Set(salespersonArr))].length) {
        //     showToast('ERROR', 'Salesperson must be unique')
        //     return;
        // }
        if (departmentArr.length !== [...Array.from(new Set(departmentArr))].length) {
            showToast('ERROR', 'Department must be unique')
            return;
        }
        // -----------------------CHECK------------------------
        setLoading(true);
        window.scrollTo(0,0)

        const reqBody: any =
        {
            customer_id: parseInt(data.customer_id),
            invoice_date: data.invoice_date.split('T')[0],
            due_date: getValues('due_date').split('T')[0],
            comments: data.comments,
            amount: data.amount,
            credit_month : getValues("credit_month"),
            invoice_status: getValues("invoice_status"),
            items: data?.milestone?.map((value: any) => {
                return { ...value, quantity: parseInt(value.quantity), unit_price: parseInt(value.unit_price) }
            }),
            department_share: data?.department?.map((value: any) => {
                return { ...value, department_id: parseInt(value.department_id), department_share: parseInt(value.department_share), department_amount: parseInt(value.department_amount) }
            }),
            sales_person_share: data?.salesperson?.map((value: any) => {
                return { ...value, sales_person_id: parseInt(value.sales_person_id), sales_person_share: parseInt(value.sales_person_share), sales_person_amount: parseInt(value.sales_person_amount) }
            }),
            temp_data:
            {
                temp_sow: autoCompleteValues.customer_sows,
                temp_milestones: autoCompleteValues.customer_milestones
            }
        };


        if (operation === 'edit') {
            reqBody.invoice_id = clickedRow?.invoice_id;
            reqBody.invoice_status = data?.invoice_status;
            reqBody.deleted_department = deleted_department;
            reqBody.deleted_sales_person = deleted_salesperson;
            if (parseInt(data.invoice_status) === 2)
                reqBody.received_amount = data?.received_amount;

            delete reqBody['customer_id']
        }
        const header =
        {
            access_token: loginData?.access_token
        }
        const reqData = {
            reqBody: reqBody,
            headers: header
        };

        console.log(reqBody);

        await ApiService[operation === 'create' ? 'post' : 'put'](InvoiceEndpoints.invoice(reqData))
            .then((res: any) => {
                console.log(res.data)
                showToast("SUCCESS",res.message)
                setOpen(false);
            navigate('/invoices' )
            }).
            catch((err: any) => {
                showToast('ERROR', err.message);
            }).
            finally(() => {
                setLoading(false);
                setDeleted_salesperson([])
                setDeleted_department([])
            });
    }

    const updateAmount = async () => {
        ///////////calculation for amount///////
        let totalAmount: any = 0;
        getValues('milestone')?.map((value: any) => {
            let price = parseInt(value.unit_price) ? parseInt(value.unit_price) : 0;
            let qty = parseInt(value.quantity) ? parseInt(value.quantity) : 0;
            totalAmount += price * qty;
        })

        setValue('amount', totalAmount);

        let totalSalespersonShare: any = 0;
console.log(getValues('salesperson'),"======salesssspersonnnnssssss====")
        getValues('salesperson')?.map((value: any, index: number) => {
            totalSalespersonShare += getValues(`salesperson.${index}.sales_person_share`) ? parseInt(getValues(`salesperson.${index}.sales_person_share`)) : 0;
            let share = getValues(`salesperson.${index}.sales_person_share`) ? parseInt(getValues(`salesperson.${index}.sales_person_share`)) : 0;
            let amt = (totalAmount / 100) * share;
            setValue(`salesperson.${index}.sales_person_amount`, Math.round(amt))
        })

        totalAmount -= Math.round((totalAmount / 100) * totalSalespersonShare);

        console.log("---------TOTAL AMOUNT-------");
        console.log(totalAmount);

        getValues('department')?.map((value: any, index: number) => {
            let share = getValues(`department.${index}.department_share`) ? parseInt(getValues(`department.${index}.department_share`)) : 0;
            let amt = (totalAmount / 100) * share;
            setValue(`department.${index}.department_amount`, Math.round(amt))
        })
    }

    const removeMilestones = async (diffArrBA: any) => {
        console.log("-------TESTING----------");
        console.log(diffArrBA);
    }

    const updateMilestone = (milestoneArr: any) => {
        if (milestoneArr?.length) {
            let arrA = milestoneArr?.map((value: any) => value.milestone_id);
            let arrB = getValues('milestone')?.map((value: any) => value.milestone_id);

            let diffArrAB = arrA?.filter((x: any) => !arrB.includes(x));
            let diffArrBA = arrB?.filter((x: any) => !arrA.includes(x));

            if (diffArrAB?.length) {
                console.log(diffArrAB);
                let mId = diffArrAB[0];
                let data = milestoneArr?.filter((value: any) => value.milestone_id === mId)
                milestoneAppend({
                    milestone_id: data[0].milestone_id,
                    description: data[0].name,
                    unit_price: data[0].amount,
                    quantity: 1,
                    sow_id: data[0].sow_id
                })
            }
            if (diffArrBA?.length) {

                if (operation === 'edit') {
                    removeMilestones(diffArrBA);
                }


                if (operation === 'create') {
                    let orgMilestoneArr = getValues('milestone');
                    for (let i = 0; i < diffArrBA.length; ++i) {
                        let mId = diffArrBA[i];
                        orgMilestoneArr?.map((value: any, index: number) => {
                            if (value.milestone_id === mId)
                                milestoneRemove(index);
                        })
                    }
                }

            }
        }
        else {
            console.log("MILESTONE ARRAY IS EMPTY");
            setValue('milestone', []);
        }
        ///////////update the amount/////////
        updateAmount();
    }

    const onMilestoneDeleteConfirm = () => {

    }
    const onMilestoneDeleteDiscard = () => {

    }

    const onConfirm = async () => {
        console.log(clickedRow);
        const reqBody =
        {
            invoice_id: clickedRow?.invoice_id
        }
        const header =
        {
            access_token: loginData?.access_token
        }
        const reqData =
        {
            reqBody: reqBody,
            headers: header
        };
        await ApiService.delete(InvoiceEndpoints.invoice(reqData))
            .then((res: any) => {
                deleteTaskRef.current.handleClose();
                showToast('SUCCESS', res.message)
                getInvoices();
            }).catch((err: any) => {
                showToast('ERROR', err.message);
                /////////set loading to false/////////
            }).finally(() => {
                setLoading(false);
            })
    }

    const onDiscard = () => {
        deleteTaskRef.current.handleClose();
    };

    const deleteSalesPerson = async () => {
        const reqBody = { sales_invoice_id: clickedRow?.sales_invoice_id }

        const header = { access_token: loginData?.access_token }

        const reqData =
        {
            reqBody: reqBody,
            headers: header
        };

        await ApiService.delete(InvoiceEndpoints.invoices_salesperson(reqData))
            .then((res: any) => {

                deleteSalespersonRef.current.handleClose();
                showToast('SUCCESS', res.message);
                getValues('salesperson')?.map((value: any, index: number) => {
                    if (value.sales_invoice_id === clickedRow?.sales_invoice_id)
                        salespersonRemove(index)
                })
                updateAmount();
            }).catch((err: any) => {
                showToast('ERROR', err.message);
                /////////set loading to false/////////
            }).finally(() => {
                setLoading(false);
            })
    }

    const closeSalespersonDialog = () => {
        deleteSalespersonRef.current.handleClose();
    };


    const deleteDepartment = async () => {
        const reqBody = { dep_inv_id: clickedRow?.dep_inv_id }

        const header = { access_token: loginData?.access_token }

        const reqData =
        {
            reqBody: reqBody,
            headers: header
        };

        await ApiService.delete(InvoiceEndpoints.invoices_department(reqData))
            .then((res: any) => {
                deleteDepartmentRef.current.handleClose();
                showToast('SUCCESS', res.message);
                getValues('department')?.map((value: any, index: number) => {
                    if (value.dep_inv_id === clickedRow?.dep_inv_id)
                        departmentRemove(index)
                })
                updateAmount();
            }).catch((err: any) => {
                showToast('ERROR', err.message);
                /////////set loading to false/////////
            }).finally(() => {
                setLoading(false);
            })
    }
const handleDelete=()=>{
    console.log("deleted")
}
    const closeDepartmentDialog = () => {
        deleteDepartmentRef.current.handleClose();
    };
    const renderMilestones = (props:any,option:any) => { 
        if(option){
            console.log(option,"---option---")
            const tempString=option?.new_milestone_name?.split('(')
            return(
                <li {...props} key={option.milestone_id}>
                <b>{tempString[0]}</b> 
     
               {"(" + tempString[1]}
               </li>
            )
        }
       
     }
     const handleData = ( option:any , index:number , getTagProps:any) =>{
        let temp = option.new_milestone_name.split("(")
      const chip = <Chip {...getTagProps({ index })} label={<span><b>{temp[0]}</b>{ "(" + temp[1]}</span>}/>
      return chip
      }
     const renderMilestoneTag =(value:any, getTagProps:any, ownerState:any)=>{
        console.log(value , ownerState,"============")
          return value.map((option:any, index:any) => (
            handleData(option , index , getTagProps)
          ));
        }    
        
        const resetFields = async () => {
        setValue('customer_id', undefined);
        setCurrency('');
        setAutocompleteValues({
            customer_sows: [],
            customer_milestones: []
        })
        setDropdownValues
            ({
                customer_id: '',
                invoice_status: ''
            })
        reset
            ({
                invoice_date: new Date().toISOString(),
                due_date: new Date().toISOString(),
                comments: '',
                amount: 0,
                milestoneFields: [],
                salespersonFields: [],
            })
        setValue('milestone', []);
        setValue('department', []);
        setValue('salesperson', []);
    }

    const calculatePercentile = (data?: any) => {
        let totalAmount = getValues("amount")
        let totalSalespersonAmount = 0;
        getValues('salesperson').map((value: any, index: number) => {
            totalSalespersonAmount += value?.sales_person_amount ? parseInt(value.sales_person_amount) : 0
            setValue(`salesperson.${index}.sales_person_share`, Math.round((getValues(`salesperson.${index}.sales_person_amount`) * 100) / totalAmount))
        })
        let remainingAmount = totalAmount - totalSalespersonAmount
        console.log(remainingAmount,"remainingamount")
        getValues('department')?.map((value: any, index: number) => {
if(data){
        setValue(`department.${index}.department_share`,Math.round((getValues(`department.${index}.department_amount`)*100) / remainingAmount))
}else{
           let share = getValues(`department.${index}.department_share`) ? getValues(`department.${index}.department_share`) : 0;
                setValue(`department.${index}.department_amount`, Math.round((remainingAmount / 100) * share));
    }
})
    }

    const handleBack=()=>{
        navigate('/invoices')
    }
    const handleDueDate=(payment_terms:any)=>{
        setValue("due_date",new Date())
        let d= getValues("due_date")? new Date(getValues("due_date")) : new Date()
    let dayCount = 0;

    dummyPaymentTermsInDays.map((value: any) => {
        if (value.id === parseInt(payment_terms)) {
            dayCount = value.value;
        }
    })
    d.setDate(d.getDate()+dayCount)
setValue("due_date",d.toISOString().split('T')[0])
    }
    return <React.Fragment>
        <Loader loading={loading}>
<div style={{maxHeight :"100vh"}}>
            <div style={{ cursor: operation === 'view' ? 'not-allowed' : 'pointer' }}>
                <div style={{ pointerEvents: operation === 'view' ? 'none' : 'visible' }}>
                    <Container maxWidth={false} style={{ ...styles.container, display: open ? 'block' : 'none' }}>
                      
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12} lg={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <h2>{operation === 'edit' ? 'Edit Invoice' : operation === 'view'? 'View Invoice':'Add Invoice'}</h2>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12} lg={12} style={{ display: 'flex', alignItems: 'center' }}>
                                    <h3>Customer</h3>
                                </Grid>
                                <Grid item xs={6} md={6} lg={6}>
                                    <Controller
                                        control={control}
                                        name={`customer_id`}
                                        render={({ field: any }) => (
                                            <DropDownComponent
                                                disabled={operation !== 'create'}
                                                value={dropdownValues.customer_id}
                                                label='Select Customer*'
                                                menuItems={customers}
                                                valueKey='customer_id'
                                                showNameKey='name'
                                                error={errors.customer_id ? true : false}
                                                onChange={(e: any) => {
                                                    ///////////reset the autocomplete////////////
                                                    setAutocompleteValues
                                                        ({
                                                            customer_sows: [],
                                                            customer_milestones: []
                                                        })
                                                    setDropdownValues
                                                        ({
                                                            ...dropdownValues, customer_id: e.target.value
                                                        })
                                                    setValue('customer_sows', []);
                                                    setValue('customer_milestones', []);
                                                    setValue('milestone', [])
                                                    updateAmount();
                                                    let tempCurrency = '';
                               
                               
                                                    customers?.map((value: any) => {
                                                        if (value.customer_id === e.target.value)
                                                            tempCurrency = value.billing_currency;
                                                    })
                                                    setCurrency(getCurrencySymbol(tempCurrency));
                                                    //////////////////////////////////////////////
                                                    setValue('customer_id', e.target.value);
                                                    getParticularCustomerSow(e.target.value);
                                                    setValue("invoice_status",1)
                                                }}
                                            />
                                        )}
                                    />
                                    <Errormessage errormessage={errors.customer_id ? errors.customer_id.message : ''} />
                                </Grid>
                            </Grid>



{/* =============================invoice details================== */}
{console.log(dropdownValues.customer_id , '==============length=========')}
<Accordion
                                        defaultExpanded={dropdownValues?.customer_id ? true : false}
                                        expanded={dropdownValues?.customer_id}
                                        style={{ margin: "20px 0px" }}
                                    >

                                        <AccordionSummary
                                            expandIcon={<ExpandMoreOutlined />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            style={{ display: "flex", justifyContent: "flex-end" }}
                                        >
                                            <h3>Invoice Details</h3>
                                        </AccordionSummary>
                                        <AccordionDetails>
                            <Grid container spacing={2}>
                               
                                <Grid item xs={6} md={6} lg={6}>
                                    <Controller
                                        control={control}
                                        name={`customer_sows`}
                                        render={({ field: any }) => (
                                            <Autocomplete
                                                multiple={true}
                                                label='Select SOW*'
                                                disabled={operation === 'view'}
                                                menuItems={sows}
                                                valueKey='sow_id'
                                                showNameKey='title'
                                                value={autoCompleteValues?.customer_sows}
                                                placeholder='add sow'
                                                getOptionSelected={(option: any, value: any) => option.sow_id === value.sow_id}
                                                onChange={(e: any, value: any) => {

                                                    console.log(value);

                                                    let sowArr: any = [];
                                                    sowArr = value?.map((value: any) => value.sow_id)

                                                    setValue('customer_sows', value);
                                                   
                                                        setValue("payment_terms",value?.[0]?.payment_terms)
                                                        handleDueDate(value?.[0]?.payment_terms)                                                        
                                                    

                                                    let newMilestoneArr: any = [];
                                                    console.log(autoCompleteValues.customer_milestones)

                                                    for (let i = 0; i < sowArr.length; ++i) {
                                                        let sowId = sowArr[i];
                                                        let tempArr: any = [];
                                                        tempArr = autoCompleteValues?.customer_milestones?.filter((value: any) => {
                                                            return sowId === value.sow_id;
                                                        })
                                                        newMilestoneArr = [...newMilestoneArr, ...tempArr];
                                                    }


                                                    updateMilestone(newMilestoneArr);

                                                    setValue('customer_milestones', newMilestoneArr);
                                                    setAutocompleteValues({ ...autoCompleteValues, customer_sows: value, customer_milestones: newMilestoneArr });

                                                    if (value.length)
                                                        getMilestones(sowArr);

                                                    else setSowMilestones([]);
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6} md={6} lg={6}>
                                    <Controller
                                        control={control}
                                        name={`customer_milestones`}
                                        render={({ field: any }) => (
                                            <Autocomplete
                                                multiple={true}
                                                menuItems={sowMilestones}
                                                renderOption={renderMilestones}
                                                disabled={operation === 'view'}
                                                valueKey='milestone_id'
                                                renderTags={renderMilestoneTag}
                                                showNameKey='new_milestone_name'
                                                getOptionSelected={(option: any, value: any) => option.milestone_id === value.milestone_id}
                                                value={autoCompleteValues?.customer_milestones}
                                                label='Select Milestones*'
                                                // limitTags={20}
                                                placeholder='add milestones'
                                                onChange={(e: any, value: any) => {
                                                    // console.log(value);
                                                    let milestoneArr: any = [];
                                                    milestoneArr = value?.map((value: any) => value.milestone_id)
                                                    setValue("customer_milestones", value)
                                                    // setValue('customer_milestones', value);
                                                    setAutocompleteValues({ ...autoCompleteValues, customer_milestones: value })
                                                    // updateSalesPerson(milestoneArr);
                                                    if (value.length) {
                                                        getSalesPerson(milestoneArr)
                                                    } else {
                                                        milestoneRemove()
                                                        salespersonRemove()
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                            



                            {/* <Grid xs={6} md={6} lg={6}> */}
                                {/* <Grid item xs={6} md={6} lg={6}>
                                    <Controller
                                        control={control}
                                        name={`customer_milestones`}
                                        render={({ field: any }) => (
                                            <Autocomplete
                                                menuItems={sowMilestones}
                                                disabled={operation === 'view'}
                                                valueKey='milestone_id'
                                                showNameKey='new_milestone_name'
                                                getOptionSelected={(option: any, value: any) => option.milestone_id === value.milestone_id}
                                                value={autoCompleteValues.customer_milestones}
                                                label='Select milestones'
                                                // limitTags={20}
                                                placeholder='add milestones'
                                                onChange={(e: any, value: any) => {
                                                    // console.log(value);
                                                    setValue('customer_milestones', value);
                                                    setAutocompleteValues({ ...autoCompleteValues, customer_milestones: value })
                                                    updateMilestone(value);
                                                }}
                                            />
                                        )}
                                    />
                                </Grid> */}

                                <Grid item xs={6} md={6} lg={6}>
                                    <Controller
                                        control={control}
                                        name="invoice_date"
                                        render={({ field: any }) => (
                                            <MyDatepicker
                                                disabled={operation === 'view'}
                                                label='Invoice Date*'
                                                value={getValues('invoice_date') ? getValues('invoice_date') : setValue('invoice_date', new Date().toISOString())}
                                                onChange={(newValue: any) => {
                                                    setValue('invoice_date', newValue.toISOString());
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={6} md={6} lg={6}>
                                    <Controller
                                        control={control}
                                        name="due_date"
                                        render={({ field: any }) => (
                                            <MyDatepicker
                                                disabled={operation === 'view'}
                                                label='Due Date*'
                                                value={getValues('due_date') ? getValues('due_date') : setValue('due_date', new Date().toISOString())}
                                                onChange={(newValue: any) => {
                                                    setValue('due_date', newValue.toISOString());
                                                }}
                                                error={errors.due_date ? true : false}
                                            />
                                        )}
                                    />
                                    <Errormessage errormessage={errors.due_date ? errors.due_date.message : ''} />
                                </Grid>

                                <Grid item xs={6} md={6} lg={6}>
                                    <Input
                                        disabled={operation === 'view'}
                                        required={false}
                                        type="text"
                                        label="Comments"
                                        register={register(`comments`)}
                                        autoFocus={false}
                                        maxRows={3}
                                    />
                                </Grid>


                                <Grid item xs={6} md={6} lg={6} style={{ marginTop: "15px" }}>
                                    <Controller
                                        control={control}
                                        name="invoice_status"
                                        render={({ field: any }) => (
                                            <DropDownComponent
                                                disabled={operation === 'view'}
                                                value={getValues("invoice_status") ? getValues("invoice_status") : dropdownValues.invoice_status}
                                                label='Invoice Status*'
                                                menuItems={invoiceStatusDropdown}
                                                valueKey='id'
                                                showNameKey='name'
                                                error={errors.invoice_status ? true : false}
                                                onChange={(e: any) => {
                                                    setValue('invoice_status', e.target.value);

                                                    if (parseInt(e.target.value) === 2) {
                                                        setValue('received_amount', 0);
                                                    }
                                                    setDropdownValues({ ...dropdownValues, invoice_status: e.target.value });
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>


                                <Grid item xs={6} md={6} lg={6}>
                                    <Controller
                                        control={control}
                                        name="credit_month"
                                        render={({ field: any }) => (
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                             <Stack spacing={3}>
                                              <DatePicker
                                              InputProps={{style:{borderRadius:"25px"}}}
                                                inputFormat="MMMM, yy"
                                                views={["month", "year"]}
                                                label="Credit Month*"
                                                
                                                disabled={operation === 'view'}

                                                // minDate={new Date("2012-03-01")}
                                                // maxDate={new Date("2023-06-01")}
                                                value={getValues("credit_month")? getValues("credit_month") : setValue("credit_month",subDays(new Date(),30).toISOString())}
                                                onChange={(e:any)=>{
                                                    console.log(new Date(e).toISOString(),"mpnth")
                                                    setValue("credit_month",new Date(e).toISOString())
                                                }}
                                                renderInput={(params) => <TextField {...params} helperText={null} />}
                                              />
                                            </Stack>
                                          </LocalizationProvider>
                                        )}
                                    />
                                </Grid>
                                </Grid>
                            </AccordionDetails>
                            </Accordion>


{/* ==================financials========================== */}

                            <Accordion
                                        defaultExpanded={dropdownValues.customer_id ? true : false }
                                        expanded={dropdownValues?.customer_id}

                                        style={{ margin: "20px 0px" }}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreOutlined />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            style={{ display: "flex", justifyContent: "flex-end" }}
                                        >
                                            <h3>Invoice Amount</h3>
                                        </AccordionSummary>
                                        <AccordionDetails>

                            <Grid container spacing={2}>
                              
                                <Grid item xs={6} md={6} lg={6}>
                                    {/* <Input
                                        disabled={true}
                                        required={true}
                                        type="text"
                                        currency={currency}
                                        isAmount={true}
                                        label="Total Amount"
                                        register={register(`amount`)}
                                    /> */}
                                    <Controller
                                        control={control}
                                        name={'amount'}

                                        render={({ field: any }) => (
                                            <FormControl focused className="col" variant='outlined' style={{ width: "100%" }}>
                                                <InputLabel className='mText' style={{ backgroundColor: "white", color: "grey",padding:"0px 20px 0px 10px" }}>Total Amount*</InputLabel>
                                                <NumberFormat
                                                    autoFocus={false}
                                                    disabled={operation === 'view'}
                                                    required={true}
                                                    thousandsGroupStyle="lakh"
                                                    displayType='input'
                                                    value={getValues('amount')}
                                                    prefix={currency ? `${currency} ` : ""}
                                                    allowNegative={false}
                                                    customInput={TextField}
                                                    thousandSeparator={true}
                                                    InputProps={{

                                                        style: {
                                                            borderRadius: "25px",

                                                            // marginTop:"10px",
                                                        }

                                                    }}
                                                    autoComplete='off'
                                                    onValueChange={(values: any) => {
                                                        const { formattedValue, value } = values;
                                                        setValue(`amount`, value)
                                                        // calculateShare();
                                                    }}
                                                    error={errors.amount ? true : false}
                                                />
                                            </FormControl>
                                        )}
                                    />

                                </Grid>
                                {parseInt(dropdownValues.invoice_status) === 2
                                    &&
                                    <Grid item xs={6} md={6} lg={6}>
                                        {/* <Input
                                            disabled={operation === 'view'}
                                            required={false}
                                            type="text"
                                            currency={currency}
                                            isAmount={true}
                                            label="Received Amount"
                                            onChange={(e: any) => {
                                                setValue('received_amount', e.target.value);
                                            }}
                                            register={register(`received_amount`)}
                                        /> */}
                                            <Controller
                                                control={control}
                                                name={'received_amount'}

                                                render={({ field: any }) => (
                                                    <FormControl focused className="col" variant='outlined' style={{ width: "100%" }}>
                                                        <InputLabel className='mText' style={{ backgroundColor: "white", color: "grey",padding:"0px 20px 0px 10px" }}>Received Amount</InputLabel>
                                                        <NumberFormat
                                                            autoFocus={false}
                                                            disabled={operation === 'view'}
                                                            required={true}
                                                            thousandsGroupStyle="lakh"
                                                            InputProps={{

                                                                style: {
                                                                    borderRadius: "25px",

                                                                    // marginTop:"10px",
                                                                }

                                                            }}
                                                            displayType='input'
                                                            value={getValues("received_amount")}
                                                            prefix={currency ? `${currency} ` : ""}
                                                            allowNegative={false}
                                                            customInput={TextField}
                                                            thousandSeparator={true}
                                                            autoComplete='off'
                                                            onValueChange={(values: any) => {
                                                                const { formattedValue, value } = values;
                                                                setValue('received_amount', value)
                                                            }}
                                                            error={errors.amount ? true : false}
                                                        />
                                                    </FormControl>
                                                )}
                                            />
                                        <Errormessage errormessage={errors.received_amount ? errors.received_amount.message : ''} />
                                    </Grid>
                                }
                            </Grid>
                            </AccordionDetails>
                            </Accordion>


 {getValues("customer_milestones")?.length ?
 <div>

     {/* ===========================milestone============================= */}
     <Accordion
                                        defaultExpanded={getValues("customer_milestones")?.length ? true : false}
                                        style={{ margin: "20px 0px" }}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreOutlined />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            style={{ display: "flex", justifyContent: "flex-end" }}
                                        >
                                           <div style={{ display: "flex", alignItems: "center" }}>
                                                <h3>Milestone</h3>
                                               
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>

                                    
                            <Grid container spacing={1}>

                             

                                {milestoneFields.map((value: any, index: number) => {
                                    setValue(`milestone.${index}.quantity`, 1)
                                    return <>
                                        <Grid item lg={12} md={12} xs={12} style={{ marginBottom: '-15px' }}>
                                            <h4>Milestone {index + 1}</h4>
                                        </Grid>

                                        <Grid item xs={6} md={6} lg={6}>
                                            <Input
                                                required={false}
                                                type="hidden"
                                                label="Milestone Id"
                                                register={register(`milestone.${index}.milestone_id`)}
                                                style={{ display: "none" }}
                                            />
                                        </Grid>

                                        <Grid item xs={6} md={6} lg={6}>
                                            <Input
                                                required={false}
                                                type="hidden"
                                                label="SOW Id"
                                                register={register(`milestone.${index}.sow_id`)}
                                                style={{ display: "none" }}
                                            />
                                        </Grid>

                                        <Grid item xs={6} md={6} lg={6}>
                                            <Input
                                                disabled={operation === 'view'}
                                                required={false}
                                                type="text"
                                                label="Milestone Description*"
                                                register={register(`milestone.${index}.description`)}
                                                error={errors?.milestone?.[index]?.description ? true : false}
                                            />
                                            <Errormessage errormessage={errors?.milestone?.[index]?.description ? errors?.milestone?.[index]?.description?.message : ''} />
                                        </Grid>

                                        <Grid item xs={6} md={6} lg={6} style={{ marginTop: "15px" }}>
                                            {/* <Input
                                                required={false}
                                                type="number"
                                                disabled={operation === 'view'}
                                                isAmount={true}
                                                currency={currency}
                                                label="Milestone amount"
                                                register={register(`milestone.${index}.unit_price`)}
                                                onChange={(e: any) => {
                                                    setValue(`milestone.${index}.unit_price`, e.target.value)
                                                    updateAmount();
                                                }}
                                                error={errors?.milestone?.[index]?.unit_price ? true : false}
                                            /> */}
                                            <Controller
                                                control={control}
                                                name={`milestone.${index}.unit_price`}

                                                render={({ field: any }) => (
                                                    <FormControl focused className="col" variant='outlined' style={{ width: "100%" }}>
                                                        <InputLabel className='mText' style={{ backgroundColor: "white", color: "grey",padding:"0px 20px 0px 10px" }}>Milestone Amount*</InputLabel>
                                                        <NumberFormat
                                                            autoFocus={false}
                                                            disabled={operation === 'view'}
                                                            required={true}
                                                            thousandsGroupStyle="lakh"
                                                            InputProps={{

                                                                style: {
                                                                    borderRadius: "25px",

                                                                    // marginTop:"10px",
                                                                }

                                                            }}
                                                            displayType='input'
                                                            value={getValues(`milestone.${index}.unit_price`)}
                                                            prefix={currency ? `${currency} ` : ""}
                                                            allowNegative={false}
                                                            customInput={TextField}
                                                            thousandSeparator={true}
                                                            autoComplete='off'
                                                            onValueChange={(values: any) => {
                                                                const { formattedValue, value } = values;
                                                                setValue(`milestone.${index}.unit_price`, value)
                                                                updateAmount()
                                                            }}
                                                            error={errors?.milestone?.[index]?.unit_price ? true : false}
                                                        />
                                                    </FormControl>
                                                )}
                                            />
                                            <Errormessage errormessage={errors?.milestone?.[index]?.unit_price ? errors?.milestone?.[index]?.unit_price?.message : ''} />
                                        </Grid>

                                        <Grid item xs={6} md={6} lg={6}>
                                            <Input
                                                required={false}
                                                type="number"
                                                // disabled={operation === 'view'}
                                                label="Quantity"
                                                disabled={true}
                                                style={{ display: "none" }}
                                                value={getValues(`milestone.${index}.quantity`)}
                                                register={register(`milestone.${index}.quantity`)}
                                                onChange={(e: any) => {
                                                    setValue(`milestone.${index}.quantity`, e.target.value)
                                                    updateAmount();
                                                }}
                                                error={errors?.milestone?.[index]?.quantity ? true : false}
                                            />
                                            <Errormessage errormessage={errors?.milestone?.[index]?.quantity ? errors?.milestone?.[index]?.quantity?.message : ''} />
                                        </Grid>
                                    </>
                                })}
                            </Grid>
                            </AccordionDetails>
                            </Accordion>



{/* ===================================salespersom======================== */}

<Accordion
                                        defaultExpanded={getValues("customer_milestones")?.length ? true : false}
                                        expanded={salespersonFields.length !== 0}
                                        style={{ margin: "20px 0px" }}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreOutlined />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            style={{ display: "flex", justifyContent: "flex-end" }}
                                        >
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <h3>Salesperson</h3>
                                                {!salespersonFields.length && <AddCircleOutlineIcon fontSize='large'
                                                    color='primary'
                                                    onClick={() => {
                                                        salespersonAppend
                                                            ({
                                                            })
                                                    }} />}
                                            </div>                                        </AccordionSummary>
                                        <AccordionDetails>


                            <Grid container spacing={2}>
            

                                {salespersonFields.map((value: any, index: number) => {
                                    console.log(salespersonFields,"=========fieldsdsdsdsdsdsdsd====")
                                    return <>
                                        <Grid item xs={12} md={12} lg={12}>
                                            <h4>Salesperson {index + 1}</h4>
                                        </Grid>

                                        <Grid item xs={4} md={4} lg={4}>
                                            <Controller
                                                control={control}
                                                name={`salesperson.${index}.sales_person_id`}
                                                render={({ field: any }) => (
                                                    <DropDownComponent
                                                        value={getValues(`salesperson.${index}.sales_person_id`)}
                                                        disabled={operation === 'view'}
                                                        label='Select Salesperson*'
                                                        menuItems={salesperson}
                                                        valueKey='sale_person_id'
                                                        showNameKey='name'
                                                        onChange={(e: any) => {
                                                            setValue(`salesperson.${index}.sales_person_id`, e.target.value);
                                                        }}
                                                        error={errors?.salesperson?.[index]?.sales_person_id ? true : false}
                                                    />
                                                )}
                                            />

                                            <Errormessage errormessage={errors?.salesperson?.[index]?.sales_person_id ? errors?.salesperson?.[index]?.sales_person_id?.message : ''} />
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4} style={{ marginTop: "-15px" }}>
                                            <Input
                                                disabled={operation === 'view'}
                                                required={false}
                                                type="number"
                                                label="Salesperson Share in %*"
                                                register={register(`salesperson.${index}.sales_person_share`)}
                                                onChange={(e: any) => {
                                                    setValue(`salesperson.${index}.sales_person_share`, e.target.value);
                                                    updateAmount();
                                                }}
                                                error={errors?.salesperson?.[index]?.sales_person_share ? true : false}
                                            />
                                            <Errormessage errormessage={errors?.salesperson?.[index]?.sales_person_share ? errors?.salesperson?.[index]?.sales_person_share?.message : ''} />
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4}>
                                            {/* <Input
                                                isAmount={true}
                                                disabled={true}
                                                required={false}
                                                type="number"
                                                currency={currency}
                                                label="Salesperson amount"
                                                register={register(`salesperson.${index}.sales_person_amount`)}
                                                error={errors?.salesperson?.[index]?.amount ? true : false}
                                            /> */}
                                            <Controller
                                                control={control}
                                                name={`salesperson.${index}.sales_person_amount`}
                                                render={({ field: any }) => (
                                                    <FormControl className='col' focused variant='outlined' style={{ width: "100%" }}>
                                                        <InputLabel className='mText' style={{ backgroundColor: "white", color: "grey",padding:"0px 20px 0px 10px" }}>Salesperson Amount*</InputLabel>
                                                        <NumberFormat
                                                            autoFocus={false}
                                                            // disabled={true}
                                                            disabled={operation === 'view'}

                                                            required={true}
                                                            thousandsGroupStyle="lakh"
                                                            InputProps={{

                                                                style: {
                                                                    borderRadius: "25px",

                                                                    // marginTop:"10px",
                                                                }

                                                            }}
                                                            displayType='input'
                                                            value={getValues(`salesperson.${index}.sales_person_amount`)}
                                                            prefix={currency ? `${currency} ` : ""}
                                                            //    allowNegative={false}
                                                            customInput={TextField}
                                                            thousandSeparator={true}
                                                            autoComplete='off'
                                                            onValueChange={(values: any) => {
                                                                const { formattedValue, value } = values;
                                                                setValue(`salesperson.${index}.sales_person_amount`, value)
                                                                calculatePercentile()
                                                                // calculateShare();

                                                            }}

                                                            error={errors?.salesperson?.[index]?.amount ? true : false}
                                                        />
                                                    </FormControl>
                                                )}

                                            />
                                            <Errormessage errormessage={errors?.salesperson?.[index]?.amount ? errors?.salesperson?.[index]?.amount?.message : ''} />
                                        </Grid>


                                        <Grid item xs={3} md={3} lg={3}>
                                            {operation !== 'view' &&
                                                <MuiDelete
                                                    fontSize='large'
                                                    color='error'
                                                    onClick={(e: any) => {
                                                        if (value.sales_invoice_id) {
                                                            let delete_salesperson = getValues(`salesperson.${index}`)
                                                            setDeleted_salesperson([...deleted_salesperson, delete_salesperson])
                                                            salespersonRemove(index)
                                                            updateAmount()
                                                            // deleteSalespersonRef.current.handleClickOpen();
                                                            // setClickedRow({ ...clickedRow, sales_invoice_id: value.sales_invoice_id })
                                                        }
                                                        else {
                                                            salespersonRemove(index)
                                                            updateAmount();
                                                        }
                                                    }}
                                                />
                                            }
{operation !== 'view' && index=== salespersonFields.length -1  &&
<AddCircleOutlineIcon
                                                        color='primary'
                                                        fontSize='large'
                                                        onClick={() => {
                                                            salespersonAppend
                                                                ({

                                                                })
                                                        }} 
                                                        />
                                                        }
                                     
                                        </Grid>
                                    </>
                                })}


                            </Grid>
                            </AccordionDetails>
                            </Accordion>




                            {/* ============================departments============================ */}


                            <Accordion
                                        defaultExpanded={getValues("customer_milestones")?.length ? true : false}
                                        expanded={departmentFields.length !== 0}
                                        style={{ margin: "20px 0px" }}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreOutlined />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            style={{ display: "flex", justifyContent: "flex-end" }}
                                        >
                                          <div style={{ display: "flex", alignItems: "center" }}>
                                                <h3>Department</h3>
                                                {!departmentFields.length && <AddCircleOutlineIcon fontSize='large'
                                                    color='primary'
                                                    onClick={() => {
                                                        departmentAppend
                                                            ({
                                                            })
                                                    }} />}
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                            <Grid container spacing={2}>
                               
                                    {/* <AddCircleOutlineSharp fontSize='large' onClick={(e: any) => {
                                        departmentAppend({})
                                    }} /> */}
                                

                                {departmentFields.map((value: any, index: number) => {
                                    return <>
                                        <Grid item xs={12} md={12} lg={12}>
                                            <h4>Department {index + 1}</h4>
                                          </Grid>

                                        <Grid item xs={4} md={4} lg={4}>
                                            <Controller
                                                control={control}
                                                name={`department.${index}.department_id`}
                                                render={({ field: any }) => (
                                                    <DropDownComponent
                                                        disabled={operation === 'view'}
                                                        label='Department'
                                                        menuItems={departments}
                                                        valueKey='department_id'
                                                        showNameKey='name'
                                                        value={getValues(`department.${index}.department_id`)}
                                                        onChange={(e: any) => {
                                                            setValue(`department.${index}.department_id`, e.target.value);
                                                        }}
                                                        error={errors?.department?.[index]?.department_id ? true : false}
                                                    />
                                                )}
                                            />
                                            <Errormessage errormessage={errors?.department?.[index]?.department_id ? errors?.department?.[index]?.department_id?.message : ''} />
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4} style={{ marginTop: "-15px" }} >
                                            <Input
                                                disabled={operation === 'view'}
                                                required={false}
                                                type="number"
                                                label="Department Share in %*"
                                                onChange={(e: any) => {
                                                    setValue(`department.${index}.department_share`, e.target.value);
                                                    updateAmount();
                                                }}
                                                register={register(`department.${index}.department_share`)}
                                                error={errors?.department?.[index]?.share ? true : false}
                                            />
                                            <Errormessage errormessage={errors?.department?.[index]?.department_share ? errors?.department?.[index]?.department_share?.message : ''} />
                                        </Grid>

                                        <Grid item xs={4} md={4} lg={4}>
                                            {/* <Input
                                                disabled={true}
                                                required={true}
                                                type="text"
                                                currency={currency}
                                                isAmount={true}
                                                label="Amount"
                                                register={register(`department.${index}.department_amount`)}
                                            /> */}
                                            <Controller
                                                control={control}
                                                name={`department.${index}.department_amount`}
                                                render={({ field: any }) => (
                                                    <FormControl className='col' focused variant='outlined' style={{ width: "100%" }}>
                                                        <InputLabel className='mText' style={{ backgroundColor: "white", color: "grey",padding:"0px 20px 0px 10px" }}>Department Amount*</InputLabel>
                                                        <NumberFormat
                                                            autoFocus={false}
                                                            // disabled={true}
                                                            disabled={operation === 'view'}

                                                            required={true}
                                                            thousandsGroupStyle="lakh"
                                                            displayType='input'
                                                            InputProps={{

                                                                style: {
                                                                    borderRadius: "25px",

                                                                    // marginTop:"10px",
                                                                }

                                                            }}
                                                            value={getValues(`department.${index}.department_amount`)}
                                                            prefix={currency ? `${currency} ` : ""}
                                                            //    allowNegative={false}
                                                            customInput={TextField}
                                                            thousandSeparator={true}
                                                            autoComplete='off'
                                                            onValueChange={(values: any) => {
                                                                const { formattedValue, value } = values;
                                                                setValue(`department.${index}.department_amount`, value)
                                                                // calculateShare();
                                                                calculatePercentile('department')
                                                            }}

                                                            error={errors?.deparment?.[index]?.amount ? true : false}
                                                        />
                                                    </FormControl>
                                                )}

                                            />
                                        </Grid>


                                        <Grid item xs={3} md={3} lg={3} >
                                            {operation !== 'view' &&
                                                <MuiDelete
                                                    fontSize='large'
                                                    color="error"
                                                    onClick={(e: any) => {
                                                        if (value.dep_inv_id) {
                                                            // deleteDepartmentRef.current.handleClickOpen();
                                                            // setClickedRow({ ...clickedRow, dep_inv_id: value.dep_inv_id })
                                                            let delete_department = getValues(`department.${index}`)
                                                            setDeleted_department([...deleted_department, delete_department])
                                                            departmentRemove(index)
                                                            // updateAmount()
                                                        }
                                                        else {
                                                            departmentRemove(index)
                                                            updateAmount();
                                                        }

                                                    }}
                                                />
                                            }
                                             {operation !== 'view' && index === departmentFields.length - 1 && <AddCircleOutlineIcon
                                                        color='primary' fontSize='large' onClick={(e: any) => {
                                                            departmentAppend
                                                                ({

                                                                })
                                                        }} />}
                         
                                            {/* <Button variant="contained"
                                                style={{ marginBottom: '-30px' }}
                                                startIcon={<MuiDelete />}
                                                color="error"
                                                disabled={operation === 'view'}
                                                onClick={(e: any) => {
                                                    if (value.dep_inv_id) {
                                                        deleteDepartmentRef.current.handleClickOpen();
                                                        setClickedRow({ ...clickedRow, dep_inv_id: value.dep_inv_id })
                                                    }
                                                    else {
                                                        departmentRemove(index)
                                                        updateAmount();
                                                    }

                                                }}
                                            >Delete</Button> */}
                                        </Grid>
                                    </>
                                })}
                            </Grid>
                            </AccordionDetails>
                            </Accordion>
</div>
:
<></>
}
                            <Grid container style={{ marginTop: "40px", gap: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Btn
                                    variant="outlined"
                                    onClick={() => 
                                       handleBack()
                                     }
                                     style={{pointerEvents: 'visible'}}
                                    size='large'
                                    // startIcon={<ArrowBackIcon />}
                                     btnText={'Back'} type={'button '}                                >
                                    
                                </Btn>

                                <Btn
                                  
                                    type="submit"
                                    disable={operation === 'view'}
                                    variant="contained" size='large' btnText={operation === 'edit' ? 'Update' : 'Create'}>
                                    
                                </Btn>
                            </Grid>
                        </form>

                    </Container>
                </div>
            </div>
            </div>
            </Loader>

            <Suspense fallback={""}>
                <AlertDialog
                    ref={deleteMilestoneRef}
                    description={"Are you sure?"}
                    title={"Delete Milestone"}
                    confirm={"Confirm"}
                    discard={"Discard"}
                    onConfirm={onMilestoneDeleteConfirm}
                    onDiscard={onMilestoneDeleteDiscard}
                ></AlertDialog>
            </Suspense>


            <Suspense fallback={""}>
                <AlertDialog
                    ref={deleteDepartmentRef}
                    description={"Are you sure you want to delete this Department?"}
                    title={"Delete Department"}
                    confirm={"Confirm"}
                    discard={"Discard"}
                    onConfirm={deleteDepartment}
                    onDiscard={closeDepartmentDialog}
                ></AlertDialog>
            </Suspense>


            <Suspense fallback={""}>
                <AlertDialog
                    ref={deleteSalespersonRef}
                    description={"Are you sure you want to delete this Salesperson?"}
                    title={"Delete Salesperson"}
                    confirm={"Confirm"}
                    discard={"Discard"}
                    onConfirm={deleteSalesPerson}
                    onDiscard={closeSalespersonDialog}
                ></AlertDialog>
            </Suspense>


            <Suspense fallback={""}>
                <AlertDialog
                    ref={deleteTaskRef}
                    description={"Are you sure you want to delete this Invoice?"}
                    title={"Delete Invoice"}
                    confirm={"Confirm"}
                    discard={"Discard"}
                    onConfirm={onConfirm}
                    onDiscard={onDiscard}
                ></AlertDialog>
            </Suspense>
    </React.Fragment >
}

export default CreateInvoice;