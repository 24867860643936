import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import './Datatable.scss';

const customStyles = {
    headCells: {
        style: {
            fontSize: '18px',
            fontWeight: '600',
        },
    },
	noData: {
		style: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			padding:"20px"
		},
	},
    cells:{
        style:{
            fontSize:"16px"
        }
    }
    
};

const Datatable = (props: any) => {
    const {columns, data, noDataComponent } = props;
    return (
        <React.Fragment>
           <DataTable
                columns={columns}
                data={data}
                pagination={true}
                highlightOnHover
                persistTableHead={true}
                striped={true}
                expandOnRowClicked={props.expandOnRowClicked || false}
responsive={false}
                noDataComponent={noDataComponent || null}
                fixedHeader={true}
                expandableRows={props.expandableRows || false}
                expandableRowsComponent={props.expandableRowsComponent || null}
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 20, 25, 50]}
                paginationComponentOptions={{
                    rowsPerPageText: 'Records per page:',
                    rangeSeparatorText: 'out of',
                }}
                customStyles={customStyles}
            />
        </React.Fragment>
    )
}
export default Datatable;