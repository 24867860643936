import { Accordion, AccordionDetails, AccordionSummary, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Menu, MenuItem, TextField, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import Loader from '../../components/Loader/Loader'
import useLocalStorage from '../../hooks/localStorage'
import useApiService from '../../services/api.service'
import Statementofworkendpoints from '../Statementofwork/Statementofwork.endpoints'
import { showToast } from '../../components/Toast/Toast'
import DepartmentEndpoints from '../Departments/Deprtment.endpoints'
import ReportEndpoints from './Report.endpoints'
import { Controller, useForm } from 'react-hook-form';
import { DateRangePicker } from 'react-date-range'
import { DateRangeOutlined, DownloadOutlined, ExpandMoreOutlined, FilterAltOffOutlined } from '@mui/icons-material'
import Datatable from '../../components/Datatable/Datatable'
import { addDays, subDays } from "date-fns";
import { pl, enGB } from "date-fns/locale";
import getCurrencySymbol from '../Statementofwork/getCurrencySymbol';
import Autocomplete from '../../components/Multiselect/Multiselect';

import moment from 'moment'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'

const styles =
{
    container: { borderRadius: '20px', minHeight: '80vh', padding: "3vh", width: "auto", margin: "100px 40px 40px 260px", backgroundColor: "white" },
    dialogcontainer: { padding: '15px' }
}
function CumulativeReports() {


    const {
        register,
        handleSubmit,
        control,
        formState: { errors, },
        getValues, setValue,
        reset
    } = useForm();

    const loginData = useLocalStorage.getItem("loginData")
    const ApiService = useApiService()
    const [loading, setLoading] = useState(false);
    const [openFilter, setOpenFilter] = useState(false)
    const [customers, setCustomers]: any = useState([]);
    const [departments, setDepartments]: any = useState([])
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [invoiceReportsData, setInvoiceReportsData] = useState([])
    const [startDate, setStartDate]: any = useState()
    const [endDate, setEndDate]: any = useState()
    const [Reset, setReset] = useState(false)
    const [months, setMonths] = useState([])
    const [filtersApplied, setFiltersApplied]:any = useState(false)
    const [accordionOpen, setAccordionOpen]:any = useState(false)
    
    const [autoCompleteValues, setAutocompleteValues]: any = useState
        ({
            customers: [],
            departments: [],
        })

    const [state, setState] = useState([
        {
            startDate:
                subDays(new Date(), 30),
            // new Date(),
            endDate:
                //   addDays(new Date(), 1),
                new Date(),
            key: "selection"
        }
    ]);

    const handleOnChange = (ranges: any) => {
        const { selection } = ranges;
        onChange(selection);
        setState([selection]);
    };

    useEffect(() => {
        getDropdownsData()
        getReports()

    }, [])

    const handleMonthsForColumns = (res: any) => {
        console.log(res,"============res===============")
        let monthArray: any = []
        var temp: any = {}
        // moment('5', 'M').format('MMMM')
        res.map((invoice: any) => {
            if (temp.hasOwnProperty(`${moment(invoice.credit_month , 'M').format("MMMM")}`)) {
                if (!temp[`${moment(invoice.credit_month , 'M').format("MMMM")}`].hasOwnProperty(`invoice_id-${invoice.department_id}-${invoice.billing_currency}-${invoice.department_name}`)) {
                    temp[`${moment(invoice.credit_month , 'M').format("MMMM")}`][`invoice_id-${invoice.department_id}-${invoice.billing_currency}-${invoice.department_name}`] = invoice.department_sum
                }
            }
            else {
                temp[`${moment(invoice.credit_month , 'M').format("MMMM")}`] = { credit_month: moment(invoice.credit_month , 'M').format("MMMM") }
                temp[`${moment(invoice.credit_month , 'M').format("MMMM")}`][`invoice_id-${invoice.department_id}-${invoice.billing_currency}-${invoice.department_name}`] = invoice.department_sum
            }
        })
        for (let i in temp) {
            monthArray.push(temp[i])
        }
        setMonths(monthArray)
    }

    const getReports = async (data?: any) => {
        const reqBody: any = {}
        const header = {
            access_token: loginData?.access_token
        }
        if (data == 'filters') {
            if (new Date(getValues("end_date")) < new Date(getValues('start_date'))) {
                showToast("ERROR", "End date should be greater than start date")
                return;
            } else {
                reqBody.start_date = getValues("start_date") ? getValues("start_date") : moment(new Date()).subtract(1, 'months')
                reqBody.end_date = getValues('end_date') ? getValues('end_date') : moment(new Date()).subtract(1, 'months')
                setReset(true)
            }

            if(getValues("departments")?.length) {
                let departmentIdArr: any[] = []
                getValues("departments")?.map((depart: any) => {
                    departmentIdArr.push(depart.department_id)
                })
                reqBody.department_id = departmentIdArr

            }
        }
        setLoading(true)
        const reqData = {
            reqBody: reqBody,
            headers: header
        };

        await ApiService.get(ReportEndpoints.cumulativeReports(reqData))
            .then((res: any) => {
                console.log("----response data-----")
                setOpenFilter(false)
                setInvoiceReportsData(res.data)
                handleMonthsForColumns(res.data)

            }).catch((err: any) => {
                showToast('ERROR', err.message);
                /////////set loading to false/////////
            })
            .finally(() => {
                setLoading(false)
            })

        // await ApiService.get(ReportEndpoints.pendingPayment(reqData))
        //     .then((res: any) => {
        //         console.log("----DATA-----")
        //         setPendingPayment(res.data)
        //     }).catch((err: any) => {
        //         showToast('ERROR', err.message);
        //         /////////set loading to false/////////
        //     })

    }
    const getDropdownsData = async () => {
        let tempCustomers: any = [];
        let tempDepartments: any = [];
        const reqBody = {}
        const header =
        {
            access_token: loginData?.access_token
        }
        const reqData =
        {
            reqBody: reqBody,
            headers: header
        };

        await ApiService.get(Statementofworkendpoints.sow_customer(reqData))
            .then((res: any) => {
                tempCustomers = res.data;
            }).catch((err: any) => {
                showToast('ERROR', err.message);
            })
        ////////////////get all the sow information////////
        // getSOWs();
        await ApiService.get(DepartmentEndpoints.department(reqData))
            .then((res: any) => {
                tempDepartments = res.data;
            }).catch((err: any) => {
                showToast("ERROR", err.message)
            }).finally(() => {

            })
        console.log("----------DROPDOWN DATA----------")

        // console.log(tempProjects);
        // console.log(tempCustomers);
        // setAllProjects(tempProjects);
        // setProjects(tempProjects);
        setCustomers(tempCustomers);
        setDepartments(tempDepartments)
    }
    const handleClose = () => {

        setOpenFilter(false);
        setAutocompleteValues({
            customers: [],
            departments: []
        })
        getReports()

    };
    const onChange = (ranges: any) => {
        if (
            moment(ranges.startDate).format("MM-DD-YYYY") !==
            moment(ranges.endDate).format("MM-DD-YYYY")
        ) {
            setValue("display_start_date", moment(ranges.startDate).format("LL"))
            setValue("display_end_date", moment(ranges.endDate).format("LL"))
            setValue("start_date", moment(ranges?.startDate).format("YYYY-MM-DD"))
            setValue("end_date", moment(ranges?.endDate).format("YYYY-MM-DD"))

            handleMenuClose()
            getReports("date")

        } else if (ranges.startDate === "" && ranges.endDate === "") {
            handleMenuClose()
        } else {
            setValue("display_start_date", moment(ranges.startDate).format("LL"))
            setValue("display_end_date", moment(ranges.endDate).format("LL"))
        }
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };


    const handleDepartmentAmount = (invoice: any, row: any) => {
        console.log(invoice, "------------------------iiiiii--------------")
        if (moment(row.credit_month , 'M').format("MMMM") == invoice.credit_month ) {
            return getCurrencySymbol(row['billing_currency']) + ' ' +  parseInt(invoice[`invoice_id-${row.department_id}-${row.billing_currency}-${row.department_name}`])?.toLocaleString('en-IN')
        } else {
            return "-"
        }
    }
    const newColumns = (month: any, invoiceData: any) => {
        console.log(moment('5', 'M').format('MMMM'))

        console.log(month, "---------------invoice datatatatata for monthhhhhh-----------")
        let temp: any = {}
        month.map((invoice: any, index: number) => {
            return (
                columns.push({
                    name: `${invoice.credit_month} (amount)`,
                    wrap: true,
                    minWidth: "125px",
                    cell: (row: any) => handleDepartmentAmount(invoice, row)
                })
            )

        })
    }

    const columns: any = [
        {
            name: "Department Name",
            selector: "department_name",
            wrap: true,
            minWidth: "125px",
        },
        // {
        //     name: "Department Amount",
        //     selector: "department_sum",
        //     wrap: true,
        //     minWidth: "125px",
        // }
    ];

    const onSubmit = (data: any) => {
        console.log(data, "----data----")
        if (data) {
            getReports(data)
        }
    }



    newColumns(months, invoiceReportsData)

    return (
        <>
            <Loader loading={loading}>
                <Container maxWidth={false} style={styles.container} className='Users'>

                    <Grid container spacing={0}>
                        <Grid item lg={12} md={12} style={{ display: 'flex', justifyContent: "space-between" }}>

                            <Grid style={{ display: 'flex', justifyContent: "center", alignItems: "center", margin: "0 auto" }}>
                                <h2>Cumulative Reports</h2>
                            </Grid>
                        </Grid>


                        <Accordion 
                    style={{ width:"100%" , margin:"20px 0px"}}
                    expanded={accordionOpen}
                    onChange={()=>setAccordionOpen(!accordionOpen)}
                    >
        <AccordionSummary
          expandIcon={<ExpandMoreOutlined/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{display:"flex", justifyContent:"flex-end"}}
        >
          <Typography style={{display:"flex" , alignItems:"center", gap:"10px"}}>Filters <span className='dot' style={filtersApplied? {backgroundColor:"green"} : {backgroundColor:"red"}}></span> </Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2} style={{ width:"100%" }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <Grid xs={6} md={6} lg={6} style={{width:"100%" , padding:"16px 0px 0px 16px"}}>
                                    <DatePicker
                                        inputFormat="MMM, yy"
                                        views={['month', 'year']}

                                        label="Start Month"
                                        //   minDate={}
                                        //   maxDate={endDate? endDate : new Date()}
                                        value={startDate ? startDate : 
                                            <>
                                            {setStartDate(moment(new Date()).subtract(1, 'months'))}
                                            {setValue('start_date' ,moment(new Date()).subtract(1, 'months'))}
                                            </>
                                        }
                                                                                InputProps={{
                                            style: {
                                                borderRadius: "25px"
                                            }
                                        }}
                                        PaperProps={{
                                            style: {
                                                borderRadius: "25px",
                                                marginLeft: "50px"
                                            }
                                        }}
                                        onChange={(e: any) => {

                                            setValue("start_date", e)

                                            console.log(new Date(e).getMonth())
                                            setStartDate(e)
                                        }
                                        }
                                        renderInput={(params: any) => <TextField {...params} helperText={null} fullWidth />}
                                    />
                                </Grid>
                                <Grid xs={6} md={6} style={{width:"100%" , padding:"16px 0px 0px 16px"}}>
                                    <DatePicker
                                        inputFormat="MMM, yy"
                                        views={['month', 'year']}
                                        label="End Month"
                                        InputProps={{
                                            style: {
                                                borderRadius: "25px"
                                            }
                                        }}
                                        PaperProps={{
                                            style: {
                                                borderRadius: "25px",
                                                marginLeft: "50px"
                                            }
                                        }}
                                        // minDate={startDate ? startDate : new Date()}
                                        //   maxDate={new Date('2023-06-01')}
                                        value={endDate ? endDate :
                                            <>
                                            {setEndDate(moment(new Date()).subtract(1, 'months'))}
                                            {setValue('end_date',moment(new Date()).subtract(1, 'months'))}
                                            </>
                                        }
                                        onChange={(e: any) => {
                                            setValue("end_date", e)
                                            setEndDate(e)
                                        }
                                        }
                                        renderInput={(params) => <TextField {...params} helperText={null} fullWidth />}
                                    />
                                </Grid>
                                {/* </Box> */}
                            </LocalizationProvider>

                            {/* <Typography>Department Filters -</Typography> */}
                            <Grid item lg={6} md={6}>
                                <Controller
                                    control={control}
                                    name={`departments`}
                                    render={({ field: any }) => (
                                        <Autocomplete
                                            multiple={true}
                                            // disabled={operation === 'view'}
                                            label='Select Department'
                                            menuItems={departments}
                                            valueKey='department_id'
                                            showNameKey='name'
                                            getOptionSelected={(option: any, value: any) => option.department_id === value.department_id} value={autoCompleteValues.departments}
                                            onChange={(e: any, value: any) => {

                                                setValue(`departments`, value);
                                                setAutocompleteValues({ ...autoCompleteValues, departments: value })

                                            }}
                                        // error={errors?.department?.[index]?.department_id ? true : false}
                                        />
                                    )}
                                />
                                {/* <Errormessage errormessage={errors?.department?.[index]?.department_id ? errors?.department?.[index]?.department_id?.message : ''} /> */}
                            </Grid>

                            <Grid xs={12} md={12} style={{ marginTop:"10px",display:"flex" , justifyContent:"flex-end" , gap:"10px" }}>
                                <Button variant='outlined'
                                    style={{ borderRadius: "25px", display: Reset ? "inline" : "none" }}
                                    onClick={() => {
                                        setStartDate(null)
                                        setEndDate(null)
                                        setValue("departments",[])

                                        setAutocompleteValues({
                                            customers: [],
                                            departments: [],
                                        })
                                        setReset(false)
                                        setAccordionOpen(false)
                                        setFiltersApplied(false)
                                        getReports()
                                    }}
                                >Reset</Button>

                                <Button variant='contained'
                                    style={{ borderRadius: "20px" }}
                                    onClick={() => {
                                        getReports("filters")
                                        setAccordionOpen(true)
                                        setFiltersApplied(true)
                                    }}

                                >Apply</Button>
                            </Grid>
                        </Grid>
</AccordionDetails>
</Accordion>










                        <Grid item lg={12} md={12}>
                            <Datatable
                                fixedHeader={true}

                                columns={columns}
                                data={invoiceReportsData}
                                noDataComponent={!loading ? invoiceReportsData?.length ? " " : " No records to display " : " "}


                            />
                        </Grid>
                    </Grid>
                </Container>
            </Loader>
        </>
    )
}

export default CumulativeReports
