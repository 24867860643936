import { Accordion, AccordionDetails, AccordionSummary, Button, Container, Grid, Link, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import Autocomplete from "../../components/Multiselect/Multiselect"
import Datatable from '../../components/Datatable/Datatable';
import Loader from '../../components/Loader/Loader';
import './Statementofwork.scss';
// -------------------ICONS IMAGES--------------
import useApiService from '../../services/api.service';
import useLocalStorage from '../../hooks/localStorage';
import { showToast } from '../../components/Toast/Toast';
//---------------------------------------------------
import { Controller, useForm } from 'react-hook-form';
import Statementofworkendpoints from './Statementofwork.endpoints';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import getCurrencySymbol from './getCurrencySymbol';
import moment from 'moment';
// -------------------ICONS IMAGES--------------
import ViewIcon from "../../assets/images/eye.svg";
import EditIcon from "../../assets/images/editIcon.svg";
import DeleteIcon from "../../assets/images/deleteIcon.svg";
import AlertDialog from '../../components/Alertdialog/Dialog';
import { Suspense } from 'react';
import {  useNavigate } from 'react-router-dom';
import { ExpandMoreOutlined } from '@mui/icons-material';
import Btn from '../../components/Button/Button';


const styles =
{
    container: { borderRadius: '20px', minHeight: '80vh', padding: "3vh", width: "100%",  margin: "100px 40px 40px 260px",backgroundColor:"white" },
    dialogcontainer: { padding: '15px' }
}



const StatementofWork =(props :any)=>{

    const loginData = useLocalStorage.getItem("loginData")
    const [loading, setLoading] = useState(false);
    const [operation, setOperation] = useState('');
    const ApiService = useApiService();
    const deleteTaskRef: any = useRef();
    const [sowData, setSowdata] = useState([]);
    const [open, setOpen] = useState(false);
    const [clickedRow, setClickedRow]: any = useState();
    const navigate = useNavigate()
    const [sowTitleList, setSowTitleList]: any = useState([])
    const [filterCustomerIdArr, setFilterCustomerIdArr]: any = useState([])
    const [sowStatus, setSowStatus] = useState([])
    const [customers, setCustomers]: any = useState([]);
    const [filterProjects, setFilterProjects]: any = useState([]);
    const [tempSOWdata, setTempSOWdata] = useState([]);
    const [allProjects, setAllProjects]: any = useState([]);
    const [projects, setProjects]: any = useState([]);

    const iconDivStyles =
    {
        display: 'flex',
        cursor: "pointer",
    }

     ////////////////get all SOW'S//////////////
     const getSOWs = async () => {
        setLoading(true);
        const reqBody = {}
        const header = {
            access_token: loginData?.access_token
        }
        const reqData = {
            reqBody: reqBody,
            headers: header
        };

        await ApiService.get(Statementofworkendpoints.sow(reqData))
            .then((res: any) => {
                setTempSOWdata(res.data);
                setSowdata(res.data);
                // statusTypeListing(res.data)
            }).catch((err: any) => {
                showToast('ERROR', err.message);
                /////////set loading to false/////////
            }).finally(() => {
               
                setLoading(false)
              
            });
    }
    ///////////////////////////////////////////


    useEffect(() => {
        getDropdownsData();
        getSOWs();
    }, [])


  
    
    const onConfirm = async () => {
        console.log(clickedRow);
        const reqBody =
        {
            sow_id: clickedRow?.sow_id
        }
        const header =
        {
            access_token: loginData?.access_token
        }
        const reqData =
        {
            reqBody: reqBody,
            headers: header
        };
        await ApiService.delete(Statementofworkendpoints.sow(reqData))
            .then((res: any) => {
                deleteTaskRef.current.handleClose();
                showToast('SUCCESS', res.message);

                const newSows = sowData?.filter((value: any) => value.sow_id !== clickedRow?.sow_id)

                setSowdata(newSows);
                // setFilterProjects(newSows);
                // setValue('projects', newSows);
                // setAutocompleteValues({ ...autoCompleteValues, projects: newSows });

            })
    }
    const handleProjectandStatusFilter = (data: any) => {
        if (data) {
            let tempProject: any = []
            let tempStatus: any = []
            let tempSowTitle: any = []
            data.map((d: any, index: any) => {
                tempProject.push({ project_id: d.project_id, project_name: d.project_name, customer_id: d.customer_id, project_type: d.project_type })
                tempSowTitle.push({ title: d.title, customer_id: d.customer_id, sow_id: d.sow_id })
                tempStatus.push({ status: d.status, name: parseInt(d.status) == 1 ? "Open" : parseInt(d.status) == 2 ? "InProgress" : parseInt(d.status) == 3 ? "Onhold" : "Completed" })
            })
            const uniqueStatus = tempStatus.filter((value: any, index: number, self: any) =>
                index === self.findIndex((t: any) => (
                    t.status === value.status && t.name === value.name
                ))
            )
            console.log(tempStatus, "=======sow title======")
            setValue("project", tempProject)
            setFilterProjects(tempProject)
            setSowTitleList(tempSowTitle)
            setSowStatus(uniqueStatus)
        }
    }
    const onDiscard = () => {
        deleteTaskRef.current.handleClose();
    };
    const TableIcons = (row: any) => {
        const handleEdit = (clickedRow: any) => {
           
            // setLoading(true);

            console.log("------------HANDLE EDIT IS CALLED----------")
            
            navigate('/add/statement-of-work' , {state:{operation:"edit",sowId:clickedRow.sow_id}})
            // setTimeout(() => {
            //     setLoading(false)
            // }, 2500)
        }

        const handleDelete = (clickedRow: any) => {
            console.log("------------HANDLE DELETE IS CALLED----------")
            setClickedRow(clickedRow)
             deleteTaskRef.current.handleClickOpen();
        }

        const handleView = (clickedRow: any) => {
            navigate('/add/statement-of-work' , {state:{operation:"view",sowId:clickedRow.sow_id}})
        }


        return (
            <>
                <div style={iconDivStyles}>
                    <div>
                        <img src={ViewIcon} onClick={() => {
                            handleView(row.row)
                        }}
                            className="icon_images"
                        />
                    </div>
                    {loginData?.user_type < 3 && <div>
                        <img src={EditIcon} onClick={() => {
                            handleEdit(row.row)
                        }}
                            className="icon_images"
                        />
                    </div>}
                    <div>
                        {loginData?.user_type < 3 && <img src={DeleteIcon}
                            className="icon_images"
                            onClick={() => {
                                handleDelete(row.row)
                            }} />}
                    </div>
                </div>
            </>
        )
    }

      ////////////////get all SOW'S//////////////
     

    const validationSchema = Yup.object().shape({
        title:
            Yup.string()
                .trim()
                .required('This field is required'),
        pm_id: Yup.string()
            .trim()
            .required('Please select a Project Manager'),
        lead_id: Yup.string()
            .trim()
            .required('Please select a Department Lead'),
        status: Yup.string()
            .trim()
            .required('SOW status is required'),
        project_detail: Yup
            .string()
            .required('This field is required'),
        payment_terms: Yup
            .string()
            .required('This field is required'),
        customer_detail: Yup
            .string()
            .required('This field is required'),
        amount: Yup.number()
            .typeError('Amount must be a number')
            .required("This field is required")
            .min(0, "Please enter a valid amount"),
        salesperson: Yup.array().of(
            Yup.object().shape({
                sale_person_id: Yup.string().required("This field is required"),
                share: Yup.number()
                    .typeError('Share must be a number')
                    .required("This field is required")
                    .min(1, "Please enter a valid share %")
                    .max(100, "Please enter a valid share %"),

            })
        ),
        end_date: Yup.date()
            .when("start_date",
                (start_date, Yup) => start_date && Yup.min(start_date, "Enter a valid end date"))
        ,
        department: Yup.array().of(
            Yup.object().shape({
                department_id: Yup.string().required("This field is required"),
                share: Yup.number()
                    .typeError('Share must be a number')
                    .required("This field is required")
                    .min(1, "Please enter a valid share %")
                    .max(100, "Please enter a valid share %")
            })
        ),
        milestone: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().required("This field is required"),
                amount: Yup.number()
                    .typeError('Amount must be a number')
                    .required("This field is required")
                    .min(0, "Please enter a valid amount"),
                type: Yup.string().required("This field is required"),
                due_date: Yup.date()
                    .when("invoice_date",
                        (invoice_date, Yup) => invoice_date && Yup.min(invoice_date, "Enter a valid due date"))
            })
        )
    })

    const {
        control,
        formState: { errors, },
        getValues, setValue,
        reset
    } = useForm({
        resolver: yupResolver(validationSchema)
    });
   
    const getStatus = (status: any) => {
        switch (parseInt(status)) {
            case 1:
                return "Open"
                break;
            case 2:
                return 'Inprogress'
                break;
            case 3:
                return "Onhold"
                break;
            case 4:
                return "Completed"
                break;
            default:
                break;
        }
    }
    const columns: any = [
        {
            name: "Project Name",
            selector: "project_name",
            wrap: true,
            minWidth: "150px",
            cell:(row:any) => {
                return <Link underline='none' style={{cursor:"pointer"}} onClick={()=>navigate('/add/statement-of-work' , {state:{operation:"view",sowId:row.sow_id}})
            }> {row['project_name']}</Link>
            }
        },
        {
            name: "Customer Name",
            selector: "customer_name",
            wrap: true,
            minWidth: "150px",
        },
        
        {
            name: "Title",
            selector: "title",
            wrap: true,
            minWidth: "175px",
        },
        {
            name: "Status",
            selector: "status",
            wrap: true,
            minWidth: "125px",
            cell: (row: any) => getStatus(row['status'])
        },
        {
            name: "Start Date",
            selector: "start_date",
            wrap: true,
            minWidth: "125px",
            cell: (row: any) => moment(row['date']).format('ll')
        },
        {
            name: "Project Type",
            selector: "project_type",
            wrap: true,
            minWidth: "150px",
        },
        {
            name: "Total Amount",
            selector: "amount",
            wrap: true,
            minWidth: "150px",
            cell: (row: any) => {
                return getCurrencySymbol(row['customer_billing_currency']) + ' ' + (row['amount'].toLocaleString('en-IN'))
            }
        },
        {
            name: "Actions",
            allowOverflow: true,
            button: true,
            selector: "is_active",
            cell: (row: any) => <div ><TableIcons row={row} /></div>
        },
    ];

    const handleCustomerFilter = () => {
        console.log(getValues("customers"), "-----customer----")

        if (getValues("customers")) {
            let tempCustomerIdArr: any = []
            getValues("customers").map((customer: any) => {
                tempCustomerIdArr.push(customer.customer_id)
            })
            setFilterCustomerIdArr(tempCustomerIdArr)
            getProjectsAndStatus(tempCustomerIdArr)
        }
    }
    const getSowFromProjects = () => {
        if (getValues("projects")) {
            let tempProjectIdArr: any = []
            getValues("projects").map((project: any) => {
                tempProjectIdArr.push(project.project_id)
            })
            getParticularSOW(tempProjectIdArr, "project")
        }
        // else if (getValues("projects").length ==0){
        //     handleCustomerFilter()
        // }

    }
    const getParticularSOW = async (param: any, filter: string) => {
        let reqBody: any = {}

        if (filter == 'title') {
            if (param.length == 0) {
                getSowFromProjects()
                return;
            }
            reqBody.title = param;
        }
        if (filter == "project") {
            if (param.length == 0) {
                handleCustomerFilter()
                return;
            } else reqBody.project_id = param;
        }

        if (filter == 'status') {
            if (param.length == 0) {
                getSowFromProjects()
                return;
            } else reqBody.status = param;
        }
        const header =
        {
            access_token: loginData?.access_token
        }
        const reqData = {
            reqBody: reqBody,
            headers: header
        };
        // setLoading(true)
        await ApiService.get(Statementofworkendpoints.sow(reqData))
            .then((res: any) => {

                setSowdata(res.data)
            })
            .catch((err: any) => {
                showToast("ERROR", err.message)
            })
            .finally(() => {
                // setLoading(false)
            })
    }
    const [autoCompleteValues, setAutocompleteValues]: any = useState
    ({
        customers: [],
        projects: [],
        sowTitle: [],
        statusType: [],
    })
    const getSOWfromTitle = () => {
        if (getValues("sow_title")) {
            let tempSOWtitle: any = []
            getValues("sow_title").map((title: any) => {
                tempSOWtitle.push(title.title)
            })
            getParticularSOW(tempSOWtitle, "title")
        }
    }
    const getSOWFromStatus = () => {
        if (getValues("sow_status")) {
            let tempSOWStatus: any = []
            getValues("sow_status").map((status: any) => {
                tempSOWStatus.push(status.status)
            })
            getParticularSOW(tempSOWStatus, "status")
        }
    }

    const getProjectsAndStatus = async (customerId: any) => {
        const reqBody = {
            customer_id: customerId
        };
        const header =
        {
            access_token: loginData?.access_token
        }
        const reqData = {
            reqBody: reqBody,
            headers: header
        };
        // setLoading(true)
        await ApiService.get(Statementofworkendpoints.sow(reqData))
            .then((res: any) => {

                // console.log(res.data,"------responseee")
                setSowdata(res.data)
                handleProjectandStatusFilter(res.data)
            })
            .catch((err: any) => {
                showToast("ERROR", err.message)
            })
            .finally(() => {
                // setLoading(false)
            })
    }

    const getDropdownsData = async () => {

        let tempProjects: any = [];
        let tempCustomers: any = [];

        const reqBody = {}
        const header =
        {
            access_token: loginData?.access_token
        }
        const reqData =
        {
            reqBody: reqBody,
            headers: header
        };

        await ApiService.get(Statementofworkendpoints.sow_project(reqData))
            .then((res: any) => {
                tempProjects = res.data;
            }).catch((err: any) => {
                showToast('ERROR', err.message);
            })

        await ApiService.get(Statementofworkendpoints.sow_customer(reqData))
            .then((res: any) => {
                tempCustomers = res.data;
            }).catch((err: any) => {
                showToast('ERROR', err.message);
            })
        ////////////////get all the sow information////////
        // getSOWs();

        console.log("----------DROPDOWN DATA----------")

        // console.log(tempProjects);
        // console.log(tempCustomers);
        setAllProjects(tempProjects);
        setProjects(tempProjects);
        setCustomers(tempCustomers);
    }




 const handleAddSow =() =>{
      
    navigate('/add/statement-of-work' , {state:{operation:"add"}})
 }
    return(
        <React.Fragment>
            <Loader loading={loading}>
            <div style={{ maxHeight: "100vh" }}>

            
            <Container maxWidth={false} style={{ ...styles.container, display: !open ? 'block' : 'none' }} className='Users'>
            <Accordion style={{margin:"10px 0px"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreOutlined/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{display:"flex", justifyContent:"flex-end"}}
        >
          <Typography>Filters</Typography>
        </AccordionSummary>
        <AccordionDetails>
                    <Grid container spacing={2} style={{ marginBottom: '20px' }}>
                        <Grid item lg={6} md={6}>
                            <Controller
                                control={control}
                                name={`customers`}
                                render={({ field: any }) => (
                                    <Autocomplete
                                        multiple={true}
                                        menuItems={customers}
                                        disabled={operation === 'view'}
                                        valueKey='customer_id'
                                        showNameKey='name'
                                        getOptionSelected={(option: any, value: any) => option.customer_id === value.customer_id}
                                        value={autoCompleteValues.customers}
                                        label='Select Customer'
                                        placeholder='add customer'
                                        onChange={(e: any, value: any) => {
                                            setValue("customers", value);
                                            handleCustomerFilter()
                                            setAutocompleteValues({ ...autoCompleteValues, customers: value })
                                        }}
                                    />
                                )}
                            />

                        </Grid>
                        <Grid item lg={6} md={6}>
                            <Controller
                                control={control}
                                name={`projects`}       
                                render={({ field: any }) => (
                                    <Autocomplete
                                        multiple={true}
                                        menuItems={filterProjects}
                                        disabled={!autoCompleteValues?.customers?.length}
                                        valueKey='project_id'
                                        showNameKey='project_name'
                                        getOptionSelected={(option: any, value: any) => option.project_id === value.project_id}
                                        value={autoCompleteValues.projects}
                                        label='Select Project'
                                        placeholder='add project'
                                        onChange={(e: any, value: any) => {
                                            setValue('projects', value);
                                            getSowFromProjects()
                                            setAutocompleteValues({ ...autoCompleteValues, projects: value })
                                            // setSowdata(value)
                                        }}
                                    />
                                )}
                            />

                        </Grid>

                        <Grid item lg={6} md={6}>

                            <Controller
                                control={control}
                                name={`status_title`}
                                render={({ field: any }) => (
                                    <Autocomplete
                                        multiple={true}
                                        menuItems={sowTitleList}
                                        disabled={!autoCompleteValues?.customers?.length}
                                        valueKey='title'
                                        showNameKey='title'
                                        getOptionSelected={(option: any, value: any) => option.sow_id === value.sow_id}
                                        value={autoCompleteValues.sowTitle}
                                        label='Select SOW Title'
                                        placeholder='add title'
                                        onChange={(e: any, value: any) => {
                                            setValue('sow_title', value);
                                            getSOWfromTitle()
                                            setAutocompleteValues({ ...autoCompleteValues, sowTitle: value })
                                            // setSowdata(value)
                                        }}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={6} md={6} lg={6} >
                            <Controller
                                control={control}
                                name={`status`}
                                render={({ field: any }) => (
                                    <Autocomplete
                                        multiple={true}
                                        menuItems={sowStatus}
                                        disabled={!autoCompleteValues?.customers?.length}
                                        valueKey='status'
                                        showNameKey='name'
                                        getOptionSelected={(option: any, value: any) => option.status === value.status}
                                        value={autoCompleteValues.statusType}
                                        label='Select Status'
                                        placeholder='add status'
                                        onChange={(e: any, value: any) => {
                                            setValue('sow_status', value);
                                            getSOWFromStatus()
                                            // getSowFromProjects()
                                            setAutocompleteValues({ ...autoCompleteValues, statusType: value })
                                            // setSowdata(value)
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    </AccordionDetails>
                    </Accordion>
        <Grid container spacing={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
        <Grid item lg={6} md={6} className='display__style__1'>
            {/* ////LEAD CAN'T ADD SOW///// */}
            {loginData?.user_type < 3 && <Btn variant="contained" type='button' onClick={() => 
                handleAddSow()
                } btnText={"Add New SOW"}></Btn> }
        </Grid>

        <Grid item lg={12} md={12}>
            {/* ------------------LISTING OF SOW------------ */}
            <Datatable
                columns={columns}
                data={sowData}
                noDataComponent={!loading ? sowData?.length ? " " : " No records to display " : " "}

            />
        </Grid>
    </Grid>
    </Container>
    <Suspense fallback={""}>
                    <AlertDialog
                        ref={deleteTaskRef}
                        description={"Are you sure you want to delete this Statement of work?"}
                        title={"Delete SOW"}
                        confirm={"Confirm"}
                        discard={"Discard"}
                        onConfirm={onConfirm}
                        onDiscard={onDiscard}
                    ></AlertDialog>
                </Suspense>
    </div>
    </Loader>
    </React.Fragment>
    )

   
}

export default StatementofWork;